import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

import { stringify } from 'qs';

import { HandleUserStorageService } from '@core/services/user/handle-storage.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGoogleService {

    private clientIdGoogle: string = environment.clientIdGoogle;
    private clientSecretGoogle: string = environment.clientSecretGoogle;
    private urlAuthGoogle: string = 'https://oauth2.googleapis.com/token?';
    private urlDataAuthGoogle: string = 'https://www.googleapis.com/oauth2/v2/userinfo';

    constructor(
        private handleUserStorageService: HandleUserStorageService
    ){}

    loadQueyGoogle( login: string ): Promise<string> {

        return new Promise( resolve => {

            // set platform in session storage
            this.handleUserStorageService.setPlatformLogin( login );

            const stringifiedParams = stringify({
                client_id: this.clientIdGoogle,
                redirect_uri: environment.urlClient,
                scope: [
                  'https://www.googleapis.com/auth/userinfo.email',
                  'https://www.googleapis.com/auth/userinfo.profile'
                ].join(' '),
                response_type: 'code',
                access_type: 'offline',
                prompt: 'consent',
            });
    
            resolve( `https://accounts.google.com/o/oauth2/v2/auth?${stringifiedParams}` );
        });

    } 

    async getAccessTokenFromCodeGoogle( code: any ) {

        const stringifiedParams = stringify({
            client_id: this.clientIdGoogle,
            client_secret: this.clientSecretGoogle,
            redirect_uri: environment.urlClient,
            grant_type: 'authorization_code',
            code
        });
        
        const response = await fetch( this.urlAuthGoogle + stringifiedParams, {
            method: 'post',
            mode: 'cors'
        });
        const data = await response.json();

        return data.access_token;
    };

    async getGoogleUserData( access_token: string ) {

        const response = await fetch( this.urlDataAuthGoogle, {
            method: 'get',
            headers : {
                Authorization: `Bearer ${access_token}`
            },
            mode: 'cors'
             
        });
        const data = await response.json();

        return data;
      };



}