import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { UtilsService } from '@core/services/utils/utils.service';
import { HandleUserStorageService } from '@core/services/user/handle-storage.service';

@Injectable({
    providedIn: 'root'
})
export class CheckUserNotLoginGuard implements CanActivate {

    constructor(
        private utilsService: UtilsService,
        private handleUserStorageService: HandleUserStorageService
    ){}

    canActivate(): Promise<boolean> {

        return new Promise( async resolve => {

            if ( !this.handleUserStorageService.getToken('Authorization') ) resolve( true )
            else {
                
                this.utilsService.routeBack();
                resolve( false );

            } 

        });

    }

}