
<div id="container-principal">

    <section id="container-form-change-password">

        <app-form-change-password (redirectTo)="redirect($event)"></app-form-change-password>
        
    </section>


    <section id="container-footer-one">

        <app-footer-one></app-footer-one>

    </section>


</div>
