

<div id="container-principal" class="animated fadeIn fast">

    <section id="container-reset-password">

        <app-form-forgot-password (redirectTo)="redirect($event)"></app-form-forgot-password>

    </section>

    <section id="container-footer-one">
        
        <app-footer-one></app-footer-one>

    </section>

</div>
