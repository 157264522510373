import { Component, ElementRef, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '@environments/environment';

import { LoginService } from '@core/services/user/auth/login.service';
import { HandleGlobalModalsService } from '@core/services/modals/handle-global-modals.service';
import { UtilsService } from '@core/services/utils/utils.service';

@Component({
  selector: 'app-form-login',
  templateUrl: './form-login.component.html',
  styleUrls: ['./form-login.component.scss']
})
export class FormLoginComponent implements AfterViewInit {

  @ViewChild('inputPassword') inputPassword: ElementRef;
  @Output() redirectTo: EventEmitter<string> = new EventEmitter();

  private PRODUCTION = environment.production;
  
  formLogin: FormGroup;
  inputPasswordHTML: HTMLElement;
  loadingBtnLogin = false;
  iconViewPassword = false;

  constructor(
    private fb: FormBuilder,
    private loginService: LoginService,
    private handleGlobalModalsService: HandleGlobalModalsService,
    private utilsService: UtilsService
  ) {

    this.formLogin = this.fb.group({
      email: ['', [
                   Validators.required,
                   Validators.pattern(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/)
                  ], ],
      password: ['', [
                      Validators.required,
                      Validators.minLength(6),
                      Validators.pattern(/[a-zA-Z0-9/\W/]{6,}/)
                     ], ]
    });

  }

  ngAfterViewInit() {
    this.inputPasswordHTML = document.getElementById('input-password-login');

  }

  // method that changes the type of password input
  viewPassword() {

    if ( this.inputPassword.nativeElement.value.length > 0 ) {

        this.iconViewPassword = !this.iconViewPassword;

        ( this.inputPassword.nativeElement.type === 'password' )
        ? this.inputPassword.nativeElement.type = 'text'
        : this.inputPassword.nativeElement.type = 'password';

    }

  }

  formValidators( field: string ): AbstractControl {
    return this.formLogin.get(field);

  }

  login() {

    if ( this.formLogin.invalid ) return;
    
    this.inputPasswordHTML.blur();
    this.loadingBtnLogin = true;

    // encrypt pass
    this.formLogin.value.password = this.PRODUCTION ? this.utilsService.passwordEncrypt( this.formLogin.value.password ) 
                                                    : this.formLogin.value.password; 

    this.loginService.loginDayvents( this.formLogin.value ).subscribe(
      () => this.redirect('/explore'),  
      (err: HttpErrorResponse) => {

        this.loadingBtnLogin = false;
        if ( err.status >= 400 && err.status < 500 ) this.handleGlobalModalsService.modalError(err);

      }
    );

  }

  redirect( direction: string ) {
    this.redirectTo.emit(direction);
  }

}
