
<div id="container-principal" class="animated fadeIn fast">

    <section id="container-form-register">

        <app-form-register (redirectTo)="redirect($event)"></app-form-register>
        
    </section>

    <section id="container-footer-one">

        <app-footer-one></app-footer-one>
        
    </section>

</div>
