

<div id="container-principal">

    <mat-icon class="icon-check_circle_outline">
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#28A745">
            <path d="M0 0h24v24H0V0zm0 0h24v24H0V0z" fill="none"/>
            <path d="M16.59 7.58L10 14.17l-3.59-3.58L5 12l5 5 8-8zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"/>
        </svg>
    </mat-icon>
    <p>{{ message }}</p>
    <button mat-raised-button [mat-dialog-close]="true" color="primary">Entendido</button>

</div>
