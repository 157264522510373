import { Injectable, ErrorHandler} from '@angular/core'
import { environment } from '@environments/environment';
import { init, captureException } from '@sentry/angular';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {

    constructor() {
        init({ dsn: environment.dsnSentry });
    }

    handleError( error: any ) {
        captureException( error.originalError || error );
    }

}

export function getErrorHandler(): ErrorHandler {
    return ( environment.production ) ? new SentryErrorHandler() : new ErrorHandler();
}