import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';

import { MatDialog } from '@angular/material/dialog';

import { Observable, throwError } from 'rxjs';
import { catchError, mergeMap} from 'rxjs/operators';

import { HandleUserStorageService } from '@core/services/user/handle-storage.service';
import { UtilsService } from '@core/services/utils/utils.service';
import { ResponseLogin } from '@core/interfaces/user/response-login';

@Injectable({
    providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

    private URLBACKEND = environment.urlBackend;

    constructor(
        private http: HttpClient,
        private router: Router,
        private matDialog: MatDialog,
        private handleUserStorageService: HandleUserStorageService,
        private utilsService: UtilsService
    ){}

    intercept( req: HttpRequest<any>, next: HttpHandler ): Observable<HttpEvent<any>> {

        const token = this.handleUserStorageService.getToken('Authorization');
        let requestClone = req;
        let idUser: string; 
        
        if ( token ) {
            
            this.utilsService.decryptUserTokenAndReturnTheId( token ).then( responseIdUser => idUser = responseIdUser );
            requestClone = this.setHeaders( req, token );

        }
        else if ( requestClone.url !== `${ this.URLBACKEND }/login-dayvents`        &&
                  requestClone.url !== `${ this.URLBACKEND }/login-google`          &&
                  requestClone.url !== `${ this.URLBACKEND }/login-facebook`        &&
                  requestClone.url !== `${ this.URLBACKEND }/users-register`        &&
                  requestClone.url !== `${ this.URLBACKEND }/users-forgot-password` &&
                  requestClone.url !== `${ this.URLBACKEND }/users-change-forgot-password` ) 
        {         
                  this.utilsService.logout();
        }  

        return next.handle( requestClone )
                   .pipe( catchError( ( err: HttpErrorResponse ) => {

                            const isRouteExploreCreateEvent = this.router.url.includes('/explore/create/event');
                            const isRouteExploreEditEvent = this.router.url.includes('/explore/edit/event');
                            
                            // debugger
                            // if ( err.error.message !== 'token invalido - authorization' ) {
                            //         console.log(err);
                            // }

                            if ( ( err.status === 0   &&  ( !isRouteExploreCreateEvent && !isRouteExploreEditEvent ) )  || 
                                 ( err.status >= 500  &&  ( !isRouteExploreCreateEvent && !isRouteExploreEditEvent ) )  || 
                                 ( err.status === 429 && 
                                   (requestClone.url !== `${ this.URLBACKEND }/login-dayvents`               &&
                                    requestClone.url !== `${ this.URLBACKEND }/users-register`               &&
                                    requestClone.url !== `${ this.URLBACKEND }/users-forgot-password`        &&
                                    requestClone.url !== `${ this.URLBACKEND }/users-change-forgot-password` &&
                                    requestClone.url !== `${ this.URLBACKEND }/users-password-change`) ) ) {

                                    if ( this.matDialog.openDialogs.length > 0 ) this.matDialog.closeAll();

                                    if ( err.status === 500 && requestClone.url.includes('/users-refresh-token') &&
                                         err.error.message === 'Ha ocurrido un error, el recurso solicitado no fue encontrado' ) 
                                    {       
                                            this.utilsService.logout();

                                    } 
                                    else this.router.navigateByUrl( '/error' );
                    
                            }
                            else if ( err.status === 403 ) {

                                if ( err.error.message === 'token invalido - authorization' ) {

                                        // refresh token
                                        return this.http.put( `${ this.URLBACKEND }/users-refresh-token`, { idUser } )
                                                .pipe( 
                                                    mergeMap( ( response: ResponseLogin ) => {
                                                    if ( response.ok ) return next.handle( this.setHeaders( req, response.Authorization ) );
                                                }) );
                                        
                                }
                                else if ( err.error.message === 'token invalido - refresh-token' ||
                                          err.error.message === 'token invalido - token-blacklist' ) 
                                {          
                                         this.utilsService.logout();   
                                }
                                
                            } 

                            else if ( err.status === 404 && requestClone.url.includes('/users-refresh-token') ) {
                                this.utilsService.logout();
                            }

                            return throwError(err);

                        })
                    );
        
    }


    setHeaders( req: HttpRequest<any>, token: string ): HttpRequest<any> {

        this.handleUserStorageService.setToken( token, 'Authorization' );
        req = req.clone({
            setHeaders: {
                'Authorization': 'Bearer' + ' ' + token
            }
        });

        return req;

    }

}