<section id="container-principal">

    <div>
        
        <app-loading-circle 
            [ngStyle]="{ 'display': 'inline-block' }"
            [diameter]="30">
        </app-loading-circle>

    </div>

</section>

