import { AfterContentInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SwPush, SwUpdate } from '@angular/service-worker';

import { UtilsService } from '@core/services/utils/utils.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterContentInit {

  constructor(
    private router: Router,
    private swPush: SwPush,
    private swUpdate: SwUpdate,
    private utilsService: UtilsService
  ) {}

  async ngOnInit() {
    
    // check network
    window.addEventListener( 'online', async () => await this.checkNetworkAndReload() );

    // hear clicks from push notifications
    if ( this.swPush.isEnabled ) this.swPush.notificationClicks.subscribe();
    this.checkAndUpdateNewVersion();

  }

  ngAfterContentInit(): void {

    const srcCropperjs = 'https://cdnjs.cloudflare.com/ajax/libs/cropperjs/1.5.12/cropper.min.js';
    window.onload = () => {
      let scriptCropperjs = document.createElement('script');
      scriptCropperjs.src = srcCropperjs;
      scriptCropperjs.async = true;
      document.head.appendChild(scriptCropperjs);
    }

  }

  async checkNetworkAndReload() {

    const network = await this.utilsService.checkNetwork();
    if ( network && this.router.url.includes('/error') ) this.utilsService.routeBack();

  }

  async checkAndUpdateNewVersion() {

    try {

      const isMustBeUpdated = await this.swUpdate.checkForUpdate();
      if ( isMustBeUpdated ) {
          await this.swUpdate.activateUpdate();
          document.location.reload();
      } 
      return true;

    } catch (error) {
        return false;
    }

  }

}