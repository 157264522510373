import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

import { stringify } from 'qs';

import { HandleUserStorageService } from '@core/services/user/handle-storage.service';


@Injectable({
    providedIn: 'root'
})
export class AuthFacebookService {

    private idAppFacebook: number = environment.idAppFacebook;
    private clientSecretFacebook: string = environment.clientSecretFacebook;
    private urlAuthFacebook: string = 'https://graph.facebook.com/v9.0/oauth/access_token?';
    private urlDataAuthFacebook: string = 'https://graph.facebook.com/me?';

    constructor(
        private handleUserStorageService: HandleUserStorageService
    ){}

    loadQueryFacebook( login: string ): Promise<string> {

        return new Promise( resolve => {

            // set platform in session storage
            this.handleUserStorageService.setPlatformLogin( login );

            const stringifiedParams = stringify({
                client_id: this.idAppFacebook,
                redirect_uri: environment.urlClient + '/',
                scope: ['email', 'user_gender'].join(','),
                response_type: 'code',
                auth_type: 'rerequest',
                display: 'page'
            });

            resolve( `https://www.facebook.com/v9.0/dialog/oauth?${ stringifiedParams }` );
        });

    }

    async getAccessTokenFromCodeFacebook(code: string): Promise<string> {

        const stringifiedParams = stringify({
            client_id: this.idAppFacebook,
            client_secret: this.clientSecretFacebook,
            redirect_uri: environment.urlClient + '/',
            code
        });
        
        const response = await fetch( this.urlAuthFacebook + stringifiedParams );
        const data = await response.json();

        return data.access_token;

    }

    async getFacebookUserData(access_token: string): Promise<any> {

        const stringifiedParams = stringify({
            fields: ['id', 'email', 'first_name', 'last_name', 'gender'].join(','),
            access_token,
        });

        const response = await fetch( this.urlDataAuthFacebook + stringifiedParams );
        const data = await response.json();
    
        return data;

    }

}