import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { filter, pluck } from 'rxjs/operators';

import { UtilsService } from '@core/services/utils/utils.service';
import { RegisterUserService } from '@core/services/user/methods/post/register.service';
import { HandleSnackbarService } from '@core/services/snackbars/handle-snackbars.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private registerUserService: RegisterUserService,
    private utilsService: UtilsService,
    private handleSnackbarService: HandleSnackbarService
  ) {

    // set page title
    this.utilsService.setPageTitle( 'Dayvents - Disfruta tu Día haciendo lo que más te gusta' );

    this.activatedRoute.queryParams
    .pipe(
      filter( query => query.tk?.length ),
      pluck( 'tk' )
    )
    .subscribe( tk => {
      this.handleSnackbarService.snackbarLoading();
      this.registerUserService.verifyAccount( tk );
    });

  }

  redirect( direction: string ) {
    this.router.navigateByUrl(direction);
  }

}
