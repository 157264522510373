import { Component, ViewChild, ElementRef, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { environment } from '@environments/environment';

import { UtilsService } from '@core/services/utils/utils.service';
import { RegisterUserService } from '@core/services/user/methods/post/register.service';
import { HandleGlobalModalsService } from '@core/services/modals/handle-global-modals.service';
import { CustomValidationFormService } from '@core/services/utils/custom-validation-form.service';

@Component({
  selector: 'app-form-register',
  templateUrl: './form-register.component.html',
  styleUrls: ['./form-register.component.scss']
})
export class FormRegisterComponent implements AfterViewInit {

  @ViewChild('inputPassword') inputPassword: ElementRef;
  @Output() redirectTo: EventEmitter<string> = new EventEmitter();

  private PRODUCTION = environment.production;

  loadingBtnRegister: boolean = false;
  iconViewPassword: boolean = false;
  formRegister: FormGroup;
  submitRegisterForm: boolean = false;
  inputPasswordHmtl: HTMLElement;

  constructor(
    private fb: FormBuilder,
    private utilsService: UtilsService,
    private registerUserService: RegisterUserService,
    private handleGlobalModalsService: HandleGlobalModalsService,
    private customValidationFormService: CustomValidationFormService
  ) { 

    this.formRegister = this.fb.group({
      name_complete: ['', { updateOn: 'blur', 
                            validators: [
                              Validators.required, 
                              Validators.minLength(4),
                              Validators.maxLength(70),
                              this.customValidationFormService.validateThatThePropertyIsNotJustBlanks 
                          ] } ],

      name_user: ['', { updateOn: 'blur', 
                        validators: [
                          Validators.required,
                          Validators.minLength(4),
                          Validators.maxLength(29),
                          this.customValidationFormService.validateThatThePropertyIsNotJustBlanks
                      ] } ],

      email: ['', { updateOn: 'blur',
                    validators: [ 
                      Validators.required,
                      Validators.pattern(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/)
                  ] } ],

      password: ['', { updateOn: 'blur', 
                       validators: [
                        Validators.required,
                        Validators.minLength(6),
                        Validators.pattern(/[a-zA-Z0-9/\W/]{6,}/),
                        this.customValidationFormService.validateThatThePropertyIsNotJustBlanks 
                       ] }],
      location: [''],
      gender: ['', [ Validators.required ] ]
    });

  }

  ngAfterViewInit() {
    this.inputPasswordHmtl = document.getElementById('input-user-password');
  }

  // method that changes the type of password input
  viewPassword(): void {

    if ( this.inputPassword.nativeElement.value.length > 0 ) {

        this.iconViewPassword = !this.iconViewPassword;

        ( this.inputPassword.nativeElement.type === 'password' )
        ? this.inputPassword.nativeElement.type = 'text'
        : this.inputPassword.nativeElement.type = 'password';

    }

  }

  formValidators( field: string ): AbstractControl {
    return this.formRegister.get(field);
  }

  async register() {

    this.submitRegisterForm = true;

    if ( this.formRegister.invalid ) return;

    this.inputPasswordHmtl.blur();
    this.loadingBtnRegister = true;

    // get location
    // const location = await this.utilsService.getLocation();
    // this.formRegister.value.location = ( !location ) ? 'puerto berrio' : location.city;
    this.formRegister.value.location = 'puerto berrio';
    
    // encrypt pass
    this.formRegister.value.password = this.PRODUCTION  ? this.utilsService.passwordEncrypt( this.formRegister.value.password ) 
                                                        : this.formRegister.value.password; 

    this.registerUserService.registerUser( this.formRegister.value ).subscribe(
      response => {

        this.loadingBtnRegister = false;
        if ( response.ok ) this.handleGlobalModalsService.modalSuccess( response.message, true, '/' );

      },
      err => {

        this.loadingBtnRegister = false;
        if ( err.status >= 400 && err.status < 500 ) this.handleGlobalModalsService.modalError(err);
        
      }
    );

  }

  redirect( redirectTo: string ) {
    this.redirectTo.emit(redirectTo); 
  }

  inputChange( whatInput: 'name_complete' | 'name_user', value: string ) {

    whatInput === 'name_complete' 
    ? this.formRegister.patchValue({ name_complete: value })
    : this.formRegister.patchValue({ name_user: value })

  }


}
