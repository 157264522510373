import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { ErrorComponent } from '@shared/modals/error/error.component';
import { SuccessComponent } from '@shared/modals/success/success.component';

@Injectable({
    providedIn: 'root'
})
export class HandleGlobalModalsService {

    constructor(
        private dialog: MatDialog,
        private router: Router
    ){}

    modalSuccess( message: string, redirect: boolean, redirectTo?: string ) {
        const dialogRef = this.dialog.open( SuccessComponent, {
            panelClass: 'dialog-success',
            data: { message }
        });

        if ( redirect === true && redirectTo ) {
            dialogRef.afterClosed().subscribe( _ => this.router.navigateByUrl(`${ redirectTo }`) );
        }

    }

    modalError( err: HttpErrorResponse | string ) {
        
        if ( err instanceof HttpErrorResponse ) {

            const error = err.error;

            if ( error.message?.error ) {
                    this.openModalError(error.message?.error);

            }
            else if (error.message) {
                    this.openModalError(error.message);

            } else {
                    this.openModalError('Ha ocurrido un error inesperado, espere un momento e inténtalo de nuevo');

            }

        } else {

            this.openModalError( err );

        }

    }
    
    private openModalError( error: string ) {
        this.dialog.open( ErrorComponent, {
            panelClass: 'dialog-error',
            data: { error }
        });
    }

}