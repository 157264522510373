import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class HandleUserStorageService {

    constructor(
        @Inject(PLATFORM_ID) private platformId: object
    ){}
    
    setToken( token: string, tokenName: string ) {
        localStorage.setItem(tokenName, token);
    }

    getToken( tokenName: string ): string | null {
        return localStorage.getItem(tokenName) || null;
    }

    removeToken( tokenName: string ) {
        localStorage.removeItem(tokenName);
    }

    removeLocalStorage() {

        localStorage.removeItem('Authorization');
        localStorage.removeItem('userCity');
        if ( isPlatformBrowser(this.platformId) ) sessionStorage.removeItem('platform');
        
    }

    setPlatformLogin( platform: string ) {
        if ( isPlatformBrowser(this.platformId) ) sessionStorage.setItem('platform', platform);
    }

    getPlatformLogin(): string | null {
        if ( isPlatformBrowser(this.platformId) ) return sessionStorage.getItem('platform') || null;
        return null;
    }
    
    setUserCity( city: string ) {
        localStorage.setItem('userCity', city);
    }

    getUserCity(): string | null {
        return localStorage.getItem('userCity') || null;
    }

    setRejectPN( dateRejectlimit: number  ) {
        localStorage.setItem('rejectPN', JSON.stringify( dateRejectlimit ));
    }

    getRejectPN(): number | null {
        return +localStorage.getItem('rejectPN') || null;
    }

    removeRejectPN() {
        localStorage.removeItem('rejectPN');
    }

    setScrollY( y: number ) {
        if ( isPlatformBrowser(this.platformId) ) sessionStorage.setItem('scrollY', JSON.stringify( y ));
    }

    getScrollY(): number {
        if ( isPlatformBrowser(this.platformId) ) return +sessionStorage.getItem('scrollY') || 0;
        return 0;
    }

    removeScrollY() {
        if ( isPlatformBrowser(this.platformId) ) sessionStorage.removeItem('scrollY');
    }

    setRedirectUrl( url: string ) {
        if ( isPlatformBrowser(this.platformId) ) sessionStorage.setItem('redirectUrl', url );
    }

    getRedirectUrl(): string {
        if ( isPlatformBrowser(this.platformId) ) return sessionStorage.getItem('redirectUrl') || null;
        return null;
    }

    removeRedirectUrl() {
        if ( isPlatformBrowser(this.platformId) ) sessionStorage.removeItem('redirectUrl');
    }
    
}