export const environment = {
  production: true,
  urlBackend: 'https://backend-dayvents.herokuapp.com/api/v1',
  urlClient: 'https://dayvents.com',
  urlSocketIO: 'https://backend-dayvents.herokuapp.com',
  tokenIpinfo: 'b113a63a386cb5',
  idAppFacebook: 816026352308104,
  clientSecretFacebook: '657962c94d01f1bc025ad73a7719cba4',
  clientIdGoogle: '1014488460118-bgcvh0kp7t7rvs0rqkek36itmcuv6kj2.apps.googleusercontent.com',
  clientSecretGoogle: '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',
  secretCryptoJS: 'KEY/SECRET/PROD/CLIENT/CRYPTOJS',
  serverPublicKey: 'BAhor3JGxwLpVOdRlQCvSxA7JdiaPjm5NwJRvkEESh7tKhBbqvy_FXhf-gCQDKKT7XgP6lWecq8cR2E4SpPM144',
  dsnSentry: 'https://75dd8ae453294e5bb5bad4d46d0a5032@o1047669.ingest.sentry.io/6024737'
};
