import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer-one',
  templateUrl: './footer-one.component.html',
  styleUrls: ['./footer-one.component.scss']
})
export class FooterOneComponent {

  year = new Date().getFullYear();

  constructor(
    private router: Router
  ) { }

  redirectTo( route: string ) {
    this.router.navigateByUrl(route);
  }

}
