import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { UserModel } from '@core/interfaces/user/user';
import { ResponseUsersBasic } from '@core/interfaces/user/response-users-basic';
import { ResponseUserModel } from '@core/interfaces/user/response-user-model';
import { UserCacheService, cacheNames } from '@core/services/user/user-cache.service';

@Injectable({
    providedIn: 'root'
})
export class DataUserService {

    private URLBACKEND = environment.urlBackend;
    userLogin: boolean;
    idParams: string;
    
    emitUserSelectedCity = new EventEmitter<string>();
    userNameEmitter =   new EventEmitter<string>();
    emitEventNumbersFollowedByUser = new EventEmitter<number>();
    emitUserNumbersFollowedByUser = new EventEmitter<number>();
    emitUserDataUpdate = new EventEmitter<{ 
        name_user: string;
        name_complete: string;
        description: string;
        contact: string;
        company: string;
    }>();

    constructor(
        private http: HttpClient,
        private userCacheService: UserCacheService
    ) {}

    getUserDataById( idUser: string ): Observable<UserModel> {
        let user$ = this.userCacheService.getValueCacheUser( idUser );
        if ( !user$ ) {
            user$ = this.http.get(`${ this.URLBACKEND }/users/${ idUser }`)
                        .pipe( 
                            map( (response: ResponseUserModel) => response.user ),
                            shareReplay(1)
                        ); 

            this.userCacheService.setValueCacheUser( idUser, user$ );
        }

        return user$;
    }

    getUserDataByToken(): Observable<UserModel> {
        return this.http.get(`${ this.URLBACKEND }/users-token`)
                        .pipe( map( (response: ResponseUserModel) => response.user ) );
    }

    getUsersByNameUser( nameUser: string, skip: number = 0, limit: number = 10 ): Observable<UserModel[]> {
        return this.http.get(`${ this.URLBACKEND }/users-name/${ nameUser }?skip=${ skip }&limit=${ limit }`)
                   .pipe( map( (response: ResponseUsersBasic) => response.users ) );
    }

    getFollowedsUsers( idUser: string, skip: number = 0, limit: number = 10 ): Observable<UserModel[]> {
        return this.http.get(`${ this.URLBACKEND }/users-followed/${ idUser }?skip=${ skip }&limit=${ limit }`)
                   .pipe( map( (response: ResponseUsersBasic) => response.users ) );
    }

    getFollowersUsers( idUser: string, skip: number = 0, limit: number = 10 ): Observable<UserModel[]> {
        return this.http.get(`${ this.URLBACKEND }/users-follower/${ idUser }?skip=${ skip }&limit=${ limit }`)
                   .pipe( map( (response: ResponseUsersBasic) => response.users ) );
    }

    getUsersWhoFollowSimilarEventCategories( skip: number = 0, limit: number = 10 ): Observable<UserModel[]> {
        const usersRequest = this.http.get(`${ this.URLBACKEND }/users-who-follow-similar-event-categories?skip=${ skip }&limit=${ limit }`);
        
        if ( skip === 0 ) {
            let users$ = this.userCacheService.getValueCacheUsersSimilarCategories();
            if ( !users$ ) {
                users$ = usersRequest.pipe( map( (response: ResponseUsersBasic) => response.users ), shareReplay(1) ); 
                this.userCacheService.setValueCacheUsersSimilarCategories( users$ );
            }
    
            return users$.pipe( map( users => users.slice(0, 10) ) );
        }

        return usersRequest.pipe( map( (response: ResponseUsersBasic) => response.users ) );
    }

    getUsersNearby( skip: number = 0, limit: number = 10 ): Observable<UserModel[]> {
        return this.http.get(`${ this.URLBACKEND }/users-nearby?skip=${ skip }&limit=${ limit }`)
                   .pipe( map( (response: ResponseUsersBasic) => response.users ) );
    }

    getAllUsers( skip: number = 0, limit: number = 10 ): Observable<UserModel[]> {
        return this.http.get(`${ this.URLBACKEND }/users?skip=${ skip }&limit=${ limit }`)
                   .pipe( map( (response: ResponseUsersBasic) => response.users ) );
    }

    cleanUserCache( cacheName: cacheNames ) {
        this.userCacheService.clearCache( cacheName );
    }

}