import { Directive, Input, ElementRef, Renderer2, OnInit, OnChanges } from '@angular/core';

import { init } from 'lazysizes';

@Directive({
  selector: '[appLazyloadFile]'
})
export class LazyloadFileDirective implements OnChanges, OnInit {

  @Input() imageSrc: string;

  private tempImage: string = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

  constructor(
    private el: ElementRef, 
    private renderer: Renderer2
  ) { }

  ngOnChanges() {
    this.setAttributes();
  }

  ngOnInit() {

    this.initLazyLoading();
    this.setAttributes();

  }

  initLazyLoading() {
    if (init) init();

  }

  setAttributes() {
    
    this.renderer.addClass(this.el.nativeElement, 'lazyload');
    if ( this.el.nativeElement.localName === 'img') this.setImgSrc();

  }

  setImgSrc () { 
    
    this.renderer.setAttribute(this.el.nativeElement, 'data-src', this.imageSrc);
    this.renderer.setAttribute(this.el.nativeElement, 'src', this.tempImage);

  }

}


