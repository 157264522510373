<footer id="container-principal">

    <section id="container-about">

        <ul>
            <li><a (click)="redirectTo( '/information/about'     )">Acerca de nosotros</a></li>
            <li><a (click)="redirectTo( '/information/guide'     )">Guía y tutoriales</a></li>
            <li><a (click)="redirectTo( '/information/questions' )">Preguntas frecuentes</a></li>
            <li><a (click)="redirectTo( '/information/terms'     )">Condiciones</a></li>
            <li><a (click)="redirectTo( '/information/privacy'   )">Política de privacidad</a></li>
            <li><a (click)="redirectTo( '/information/events'    )">Política de eventos</a></li>
            <br/>
            <small>&copy; {{ year }} Dayvents</small>
        </ul>

    </section>

</footer>
