import { Injectable } from '@angular/core';

import { MatSnackBar } from '@angular/material/snack-bar';
import { LoadingSnackbarComponent } from '@shared/components/loadings/loading-snackbar/loading-snackbar.component';

@Injectable({
    providedIn: 'root'
})
export class HandleSnackbarService {

    constructor(
        private snackBar: MatSnackBar
    ){}

    snackbarSuccess( message: string ) {
        this.snackBar.open(message, 'X', {
            duration: 2000
        }); 
    }

    snackBarInformation( message: string ) {
        this.snackBar.open( message, 'X', {
            duration: 5000
        });
    }

    snackbarLoading() {
        this.snackBar.openFromComponent( LoadingSnackbarComponent ); 
    }

    closeSnackbar() {
        this.snackBar.dismiss();
    }

}