import { Directive, Input, HostListener, EventEmitter, Output } from '@angular/core';
import { Platform } from '@angular/cdk/platform';

@Directive({
  selector: '[appCustomMaximumLengthInput]'
})
export class CustomMaximumLengthInputDirective {

  @Input()  inputMaxLength: number;
  @Output() inputChange: EventEmitter<string> = new EventEmitter()

  constructor(
    private platform: Platform
  ) {}

  @HostListener('keyup', ['$event']) 
  onKeyupEvent( event: KeyboardEvent ) {

    const elementHTML = event.target as HTMLInputElement;
    if ( ( this.platform.ANDROID || this.platform.IOS || this.platform.SAFARI ) && this.inputMaxLength ) {

      const inputValue = elementHTML.value.substring(0, this.inputMaxLength);

      inputValue.length <= this.inputMaxLength 
      ? elementHTML.value = inputValue 
      : elementHTML.value = elementHTML.value.substring(0, this.inputMaxLength - 1);

      this.inputChange.emit( elementHTML.value );

    }

  }

}
