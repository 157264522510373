import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { EventDB } from '@core/interfaces/event/event';
import { ResponseEventsBasic } from '@core/interfaces/event/response-events-basic';
import { ResponseEventBasic } from '@core/interfaces/event/response-event-basic';
import { FilteredEvent } from '@core/interfaces/event/filtered-event';
import { ResponseEventId } from '@core/interfaces/event/response-event-id';
import { ResponseEventRelevance } from '@core/interfaces/event/event-relevance';
import { EventCacheService } from '@core/services/event/event-cache.service';

@Injectable({
    providedIn: 'root'
})

export class EventService {

    private URLBACKEND = environment.urlBackend;
    
    filterEventEmitter: EventEmitter<FilteredEvent> = new EventEmitter();
    eventHourClockEmitter: EventEmitter<{selectedHour: string, eventTime: 'initial' | 'final'}> = new EventEmitter();

    constructor(
        private http: HttpClient,
        private eventCacheService: EventCacheService
    ){}

    getAllEvents( skip?: number ): Observable<EventDB[]> {
        return this.http.get(`${ this.URLBACKEND }/events?skip=${ skip }`)
                   .pipe( map( (response: ResponseEventsBasic) => response.events ) );
    }
    
    getEventById( idEvent: string ): Observable<EventDB> {
        return this.http.get(`${ this.URLBACKEND }/events/${ idEvent }`)
                   .pipe( map( ( response: ResponseEventBasic ) => response.event ) );
    }
   
    getEventsByLocation( location: string, skip: number = 0, limit: number = 10 ): Observable<EventDB[]> {
        return this.http.get(`${ this.URLBACKEND }/events-by-location/${ location }?skip=${ skip }&limit=${ limit }`)
                    .pipe( map( (response: ResponseEventsBasic) => response.events ) );
    }

    getEventsRelevantInTheGlobalCurrently( skip: number = 0 ): Observable<EventDB[]> {
        return this.http.get(`${ this.URLBACKEND }/events-relevant-currently-global?skip=${ skip }`)
                   .pipe( map( (response: ResponseEventsBasic) => response.events ) );
    }

    getEventsRelevantInTheGlobalPreviously( skip: number = 0 ): Observable<EventDB[]> {
        return this.http.get(`${ this.URLBACKEND }/events-relevant-previously-global?skip=${ skip }`)
                   .pipe( map( (response: ResponseEventsBasic) => response.events ) );
    }

    getEventsRelevantInTheCityCurrently( location: string, skip: number = 0 ): Observable<EventDB[]> {
        return this.http.get(`${ this.URLBACKEND }/events-relevant-currently-city/${ location }?skip=${ skip }`)
                   .pipe( map( (response: ResponseEventsBasic) => response.events ) );
    }

    getEventsRelevantInTheCityPreviously( location: string, skip: number = 0 ): Observable<EventDB[]> {
        return this.http.get(`${ this.URLBACKEND }/events-relevant-previously-city/${ location }?skip=${ skip }`)
                   .pipe( map( (response: ResponseEventsBasic) => response.events ) );
    }

    getEventByIdAndOnlyByTheUserWhoCreatedIt( idEvent: string ): Observable<EventDB> {
        return this.http.get(`${ this.URLBACKEND }/events-by-id-and-by-user-who-created-it/${ idEvent }`)
                   .pipe( map( ( response: ResponseEventBasic ) => response.event ) );
    }

    getEventsFollowedByUser( idUser: string, skip: number = 0, limit: number = 10 ): Observable<EventDB[]> {
        return this.http.get(`${ this.URLBACKEND }/events-followed-by-user/${ idUser }?skip=${ skip }&limit=${ limit }`)
                   .pipe( map( (response: ResponseEventsBasic) => response.events ) );
    }

    getEventsByUser( idUser: string, skip: number = 0, limit: number = 10 ): Observable<EventDB[]> {
        return this.http.get(`${ this.URLBACKEND }/events-by-user/${ idUser }?skip=${ skip }&limit=${ limit }`)
                   .pipe( map( (response: ResponseEventsBasic) => response.events ) );
    }

    getEventsCreatedByLoggedInUser( skip: number = 0, limit: number = 10 ): Observable<EventDB[]> {
        const eventsRequest = this.http.get(`${ this.URLBACKEND }/events-created-by-logged-in-user?skip=${ skip }&limit=${ limit }`);

        if ( skip === 0 ) {
            let events$ = this.eventCacheService.getValueCacheEventsCreatedByUser();
            if ( !events$ ) {
                events$ = eventsRequest.pipe( map( (response: ResponseEventsBasic) => response.events ), shareReplay(1) ); 
                this.eventCacheService.setValueCacheEventsCreatedByUser( events$ );
            }
    
            return events$.pipe( map( events => events.slice(0, 9) ) );
        }

        return eventsRequest.pipe( map( (response: ResponseEventsBasic) => response.events ) );
    }

    getEventsPausedByLoggedInUser( skip: number = 0, limit: number = 10 ): Observable<EventDB[]> {
        return this.http.get(`${ this.URLBACKEND }/events-paused-by-logged-in-user?skip=${ skip }&limit=${ limit }`)
                   .pipe( map( (response: ResponseEventsBasic) => response.events ) );
    }

    getMainEventsForUser( dataUser: { selectedCity: string, locationUser: string, citiesSearchedByUser: string[], category?: string } ): Observable<EventDB[]> {
        return this.http.post(`${ this.URLBACKEND }/events-main-for-user`, dataUser )
                   .pipe( map( (response: ResponseEventsBasic) => response.events ) );
    }

    getFilteredEvents( dataEvent: FilteredEvent, skip: number = 0, limit: number = 10 ): Observable<EventDB[]> {
        return this.http.post(`${ this.URLBACKEND }/events-filtered?skip=${ skip }&limit=${ limit }`, dataEvent )
                   .pipe( map( (response: ResponseEventsBasic) => response.events ) );
    }

    getRecommendedEvent(): Observable<string> {
        return this.http.get( `${ this.URLBACKEND }/events-user-recommend` )
                   .pipe( map( (response: ResponseEventId) => response.event ) );
    }

    getEventRelevance( idEvent: string ): Observable<ResponseEventRelevance> {
        return this.http.get<ResponseEventRelevance>( `${ this.URLBACKEND }/events-relevance/${ idEvent }` );
    }

    cleanEventCache() {
        this.eventCacheService.clearCache();
    }

}