import { AfterViewInit, Component, ElementRef, Input } from '@angular/core';

@Component({
  selector: 'app-loading-circle',
  templateUrl: './loading-circle.component.html',
  styleUrls: ['./loading-circle.component.scss']
})
export class LoadingCircleComponent implements AfterViewInit {

  @Input() diameter = 20;
  @Input() strokeWidth = 2;
  @Input() color = '#1e81ce';

  constructor(
    private el: ElementRef
  ) { }

  ngAfterViewInit() {

    const appLoadingCircleTemplate = this.el.nativeElement;
    const circle = appLoadingCircleTemplate.querySelector('circle');
    circle.style.stroke = this.color;

  }

}
