<div id="container-principal">

    <div class="icon-calendar">
        <img src="https://dayvents.com/assets/icons/logo-dayvents.svg" height="52" width="52" alt="logo-dayvents.svg">  
    </div>

    <p class="cite">
        "Registrate y empieza a disfrutar tu día"
    </p>

        <!-- form register -->
        <form class="form-register" [formGroup]="formRegister" (ngSubmit)="register()">
    
            <div>
    
                <mat-form-field appearance="outline">

                    <mat-label>Nombre completo</mat-label>
                    <input matInput 
                           appCustomMaximumLengthInput [inputMaxLength]="70" (inputChange)="inputChange( 'name_complete',  $event )"
                           type="text"
                           formControlName="name_complete"
                           #name_complete
                           minlength="4"
                           maxlength="70"
                           autocomplete="off">

                    <mat-hint align="end">{{ name_complete.value.length }} / 70</mat-hint>

                    <!-- errors -->
                    <mat-error *ngIf="formValidators('name_complete').errors?.minlength">
                        El nombre es demasiado corto
                    </mat-error>
                     
                </mat-form-field>
    
            </div>

            <div>
                
                <mat-form-field appearance="outline">

                    <mat-label>Nombre de usuario</mat-label>
                    <input matInput 
                           appCustomMaximumLengthInput [inputMaxLength]="29" (inputChange)="inputChange( 'name_user',  $event )"
                           type="text" 
                           formControlName="name_user"
                           #name_user
                           minlength="4"
                           maxlength="29"
                           autocomplete="off">

                    <mat-hint align="end">{{ name_user.value.length }} / 29</mat-hint>
                    
                    <!-- errors -->
                    <mat-error *ngIf="formValidators('name_user').errors?.minlength">
                        El nombre de usuario es demasiado corto
                    </mat-error>

                </mat-form-field>
    
            </div>

            <div>
                
                <mat-form-field appearance="outline">

                  <mat-label>Correo</mat-label>
                  <input matInput
                         appTrimBlackInput
                         type="text" 
                         formControlName="email"
                         autocomplete="off">

                        <mat-error *ngIf="formValidators('email').errors?.pattern">
                            El correo es invalido
                        </mat-error>

                </mat-form-field>
    
            </div>
    
            <div>
                
                <mat-form-field appearance="outline">
                    
                  <mat-label>Contraseña</mat-label>
                  <input matInput 
                         type="password"
                         id="input-user-password"
                         formControlName="password" 
                         #inputPassword 
                         minlength="6"
                         autocomplete="off">

                        <mat-icon class="icon-password-hide" 
                                  matSuffix 
                                  (click)="viewPassword()"
                                  *ngIf="!iconViewPassword">
                                  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                                    <path d="M0 0h24v24H0zm0 0h24v24H0zm0 0h24v24H0zm0 0h24v24H0z" fill="none"/>
                                    <path d="M12 7c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C10.74 7.13 11.35 7 12 7zM2 4.27l2.28 2.28.46.46C3.08 8.3 1.78 10.02 1 12c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L19.73 22 21 20.73 3.27 3 2 4.27zM7.53 9.8l1.55 1.55c-.05.21-.08.43-.08.65 0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2zm4.31-.78l3.15 3.15.02-.16c0-1.66-1.34-3-3-3l-.17.01z"/>
                                  </svg>
                        </mat-icon>
                            
                        <mat-icon class="icon-password-view" 
                                  matSuffix 
                                  (click)="viewPassword()"
                                  *ngIf="iconViewPassword">
                                  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"><path d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"/>
                                  </svg>
                        </mat-icon>

                        <!-- errors -->
                        <mat-error *ngIf="formValidators('password').errors?.minlength">
                            La contraseña es demasiada corta
                        </mat-error>

                        <mat-error *ngIf="formValidators('password').errors?.pattern &&
                                          !formValidators('password').errors?.minlength &&
                                          !formValidators('password').errors?.maxlength">
                            La contraseña es insegura
                        </mat-error>

                </mat-form-field>
    
            </div>

            <div id="container-gender">

                <section>
                    Sexo
                    <mat-error *ngIf="formValidators('gender').errors?.required && submitRegisterForm">
                        Debes selecionar un genero
                    </mat-error>
                </section>
                
                <mat-radio-group formControlName="gender">
                    <mat-radio-button value="male">Hombre</mat-radio-button>
                    <mat-radio-button value="female">Mujer</mat-radio-button>
                    <mat-radio-button value="other">No quiero especificar</mat-radio-button>
                </mat-radio-group>
 

            </div>

            <button mat-raised-button 
                    color="primary" 
                    class="btn-register"
                    [disabled]="loadingBtnRegister">

                <span *ngIf="!loadingBtnRegister">Registrarme</span>

                <app-loading-circle 
                    [ngStyle]="{ 'display': 'inline-block' }"
                    [diameter]="20"
                    [color]="'#9DADB8'"
                    *ngIf="loadingBtnRegister">
                </app-loading-circle>
                <span *ngIf="loadingBtnRegister">Registrando</span>
            </button>

            <p class="container-conditions-use">
                Al registrarte, aceptas nuestras 
                   <a href="/information/terms"   target="_blank">Condiciones</a>,
                la <a href="/information/privacy" target="_blank">Política de privacidad</a> y 
                la <a href="/information/events"  target="_blank">Política de eventos</a>.
            </p>
    

        </form>

        <section class="container-login">
            <p>Tengo una cuenta, <a (click)="redirect('/')">Ingresar</a> </p>
        </section>


</div>
