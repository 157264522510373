import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-change-forgot-password',
  templateUrl: './change-forgot-password.component.html',
  styleUrls: ['./change-forgot-password.component.scss']
})
export class ChangeForgotPasswordComponent {

  constructor(
    private router: Router
  ) { }

  redirect( direction: string ) {
    this.router.navigateByUrl(direction);
  }

}
