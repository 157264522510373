<section id="container-principal" *ngIf="error">

    <!-- icon error svg -->
    <svg xmlns="http://www.w3.org/2000/svg"
        height="200px" viewBox="0 0 24 24" 
        width="200px" fill="#cf4235">
        <path d="M0 0h24v24H0V0z" fill="none"/>
        <path d="M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"/>
    </svg>

    <p class="text-one">Oops!!!</p>
    <p class="text-two">{{ error }}</p>

    <button mat-stroked-button
            (click)="reload()">

            <i class="fas fa-circle-notch"></i>
            Volver
    </button>


</section>

