import { Injectable } from '@angular/core';
import { FormControl, AbstractControl } from '@angular/forms';

import { UtilsService } from '@core/services/utils/utils.service';

@Injectable({
  providedIn: 'root'
})
export class CustomValidationFormService {

  public categoriesValid = [
    'diversión'     ,  'salud'      ,  'deporte' ,     'fiesta',  
    'cine'          ,  'religión'   ,  'cultura' ,     'educación',
    'emprendimiento',  'tecnología' ,  'empleo'  ,     'juegos de video',  
    'lectura'       ,  'competencia',  'música'  ,     'promociones',      
    'comida'        ,  'fitness'    ,  'política',     'ganadería',
    'otra'
  ];

  public audienceValid = [
    'mayores de edad', 'menores de edad', 
    'general', 'hombres', 'mujeres', 'niños(as)'
  ];

  private citiesColombia = [
    "Leticia",
    "Puerto Nariño",
    "Abejorral",
    "Abriaquí",
    "Alejandría",
    "Amagá",
    "Amalfi",
    "Andes",
    "Angelópolis",
    "Angostura",
    "Anorí",
    "Anzá",
    "Apartadó",
    "Arboletes",
    "Armenia",
    "Barbosa",
    "Bello",
    "Belmira",
    "Betania",
    "Betulia",
    "Briceño",
    "Buriticá",
    "Cáceres",
    "Caicedo",
    "Caldas",
    "Campamento",
    "Cañasgordas",
    "Caracolí",
    "Caramanta",
    "Carepa",
    "Carolina del, Príncipe",
    "Caucasia",
    "Chigorodó",
    "Cisneros",
    "Ciudad Bolívar",
    "Cocorná",
    "Concepción",
    "Concordia",
    "Copacabana",
    "Dabeiba",
    "Donmatías",
    "Ebéjico",
    "El Bagre",
    "El Carmen de Viboral",
    "El Peñol ",
    "El Retiro",
    "El Santuario",
    "Entrerríos",
    "Envigado",
    "Fredonia",
    "Frontino",
    "Giraldo",
    "Girardota",
    "Gómez Plata",
    "Guadalupe",
    "Guarne",
    "Guatapé",
    "Heliconia",
    "Hispania",
    "Itagüí",
    "Ituango",
    "Jardín",
    "La Ceja",
    "La Estrella",
    "La Pintada",
    "La Unión",
    "Liborina",
    "Maceo",
    "Marinilla",
    "Medellín",
    "Montebello",
    "Murindó",
    "Mutatá",
    "Nariño",
    "Nechí",
    "Necoclí",
    "Olaya",
    "Peque",
    "Pueblo Rico",
    "Puerto Berrío",
    "Puerto Nare",
    "Puerto Triunfo",
    "Remedios",
    "Rionegro",
    "Sabaneta",
    "Salgar",
    "San Andrés de Cuerquia",
    "San Carlos",
    "San Francisco",
    "San Jerónimo",
    "San José de la Montaña",
    "San Juan de Urabá",
    "San Luis",
    "San Pedro de Urabá",
    "San Pedro de los Milagros",
    "San Rafael",
    "San Roque",
    "San Vicente",
    "Santa Fe de Antioquia",
    "Santa Rosa de Osos",
    "Santo Domingo",
    "Segovia",
    "Sonsón",
    "Sopetrán",
    "Támesis",
    "Tarazá",
    "Tarso",
    "Titiribí",
    "Toledo",
    "Turbo",
    "Uramita",
    "Urrao",
    "Valdivia",
    "Valparaíso",
    "Vegachí",
    "Venecia",
    "Vigía del Fuerte",
    "Yalí",
    "Yarumal",
    "Yolombó",
    "Yondó",
    "Zaragoza",
    "Arauca",
    "Arauquita",
    "Cravo Norte",
    "Fortul",
    "Puerto Rondón",
    "Saravena",
    "Tame",
    "Baranoa",
    "Barranquilla",
    "Campo de la Cruz",
    "Candelaria",
    "Galapa",
    "Juan de Acosta",
    "Luruaco",
    "Malambo",
    "Manatí",
    "Palmar de Varela",
    "Piojó",
    "Polonuevo",
    "Ponedera",
    "Puerto Colombia",
    "Repelón",
    "Sabanagrande",
    "Sabanalarga",
    "Santa Lucía",
    "Santo Tomás",
    "Soledad",
    "Suán",
    "Tubará",
    "Usiacurí",
    "Achí",
    "Altos del Rosario",
    "Arenal",
    "Arjona",
    "Arroyohondo",
    "Barranco de Loba",
    "Brazuelo de Papayal",
    "Calamar",
    "Cantagallo",
    "Cartagena de Indias",
    "Cicuco",
    "Clemencia",
    "Córdoba",
    "El Carmen de Bolívar",
    "El Guamo",
    "El Peñón",
    "Hatillo de Loba",
    "Magangué",
    "Mahates",
    "Margarita",
    "María la Baja",
    "Mompós",
    "Montecristo",
    "Morales",
    "Norosí",
    "Pinillos",
    "Regidor",
    "Río Viejo",
    "San Cristóbal",
    "San Estanislao",
    "San Fernando",
    "San Jacinto del Cauca",
    "San Jacinto",
    "San Juan Nepomuceno",
    "San Martín de Loba",
    "San Pablo",
    "Santa Catalina",
    "Santa Rosa",
    "Santa Rosa del Sur",
    "Simití",
    "Soplaviento",
    "Talaigua Nuevo",
    "Tiquisio",
    "Turbaco",
    "Turbaná",
    "Villanueva",
    "Zambrano",
    "Almeida",
    "Aquitania",
    "Arcabuco",
    "Belén",
    "Berbeo",
    "Betéitiva",
    "Boavita",
    "Boyacá",
    "Buenavista",
    "Busbanzá",
    "Campohermoso",
    "Cerinza",
    "Chinavita",
    "Chiquinquirá",
    "Chíquiza",
    "Chiscas",
    "Chita",
    "Chitaraque",
    "Chivatá",
    "Chivor",
    "Ciénega",
    "Cómbita",
    "Coper",
    "Corrales",
    "Covarachía",
    "Cubará",
    "Cucaita",
    "Cuítiva",
    "Duitama",
    "El Cocuy",
    "El Espino",
    "Firavitoba",
    "Floresta",
    "Gachantivá",
    "Gámeza",
    "Garagoa",
    "Guacamayas",
    "Guateque",
    "Guayatá",
    "Güicán",
    "Iza",
    "Jenesano",
    "Jericó",
    "La Capilla",
    "La Uvita",
    "La Victoria",
    "Labranzagrande",
    "Macanal",
    "Maripí",
    "Miraflores",
    "Mongua",
    "Monguí",
    "Moniquirá",
    "Motavita",
    "Muzo",
    "Nobsa",
    "Nuevo Colón",
    "Oicatá",
    "Otanche",
    "Pachavita",
    "Páez",
    "Paipa",
    "Pajarito",
    "Panqueba",
    "Pauna",
    "Paya",
    "Paz del Río",
    "Pesca",
    "Pisba",
    "Puerto Boyacá",
    "Quípama",
    "Ramiriquí",
    "Ráquira",
    "Rondón",
    "Saboyá",
    "Sáchica",
    "Samacá",
    "San Eduardo",
    "San José de Pare",
    "San Luis de Gaceno",
    "San Mateo",
    "San Miguel de Sema",
    "San Pablo de Borbur",
    "Santa María",
    "Santa Rosa de Viterbo",
    "Santa Sofía",
    "Santana",
    "Sativanorte",
    "Sativasur",
    "Siachoque",
    "Soatá",
    "Socha",
    "Socotá",
    "Sogamoso",
    "Somondoco",
    "Sora",
    "Soracá",
    "Sotaquirá",
    "Susacón",
    "Sutamarchán",
    "Sutatenza",
    "Tasco",
    "Tenza",
    "Tibaná",
    "Tibasosa",
    "Tinjacá",
    "Tipacoque",
    "Toca",
    "Togüí",
    "Tópaga",
    "Tota",
    "Tunja",
    "Tununguá",
    "Turmequé",
    "Tuta",
    "Tutazá",
    "Úmbita",
    "Ventaquemada",
    "Villa de Leyva",
    "Viracachá",
    "Zetaquira",
    "Aguadas",
    "Anserma",
    "Aranzazu",
    "Belalcázar",
    "Chinchiná",
    "Filadelfia",
    "La Dorada",
    "La Merced",
    "Manizales",
    "Manzanares",
    "Marmato",
    "Marquetalia",
    "Marulanda",
    "Neira",
    "Norcasia",
    "Pácora",
    "Palestina",
    "Pensilvania",
    "Riosucio",
    "Risaralda",
    "Salamina",
    "Samaná",
    "San José",
    "Supía",
    "Victoria",
    "Villamaría",
    "Viterbo",
    "Albania",
    "Belén de los Andaquíes",
    "Cartagena del Chairá",
    "Curillo",
    "El Doncello",
    "El Paujil",
    "Florencia",
    "La Montañita",
    "Milán",
    "Morelia",
    "San José del Fragua",
    "San Vicente del Caguán",
    "Solano",
    "Solita",
    "Aguazul",
    "Chámeza",
    "Hato Corozal",
    "La Salina",
    "Maní",
    "Monterrey",
    "Nunchía",
    "Orocué",
    "Paz de Ariporo",
    "Pore",
    "Recetor",
    "Sácama",
    "San Luis de Palenque",
    "Támara",
    "Tauramena",
    "Trinidad",
    "Yopal",
    "Almaguer",
    "Balboa",
    "Bolívar",
    "Buenos Aires",
    "Cajibío",
    "Caldono",
    "Caloto",
    "Corinto",
    "El Tambo",
    "Guachené",
    "Guapí",
    "Inzá",
    "Jambaló",
    "La Sierra",
    "La Vega",
    "López de Micay",
    "Mercaderes",
    "Miranda",
    "Padilla",
    "Patía",
    "Piamonte",
    "Piendamó",
    "Popayán",
    "Puerto Tejada",
    "Puracé",
    "Rosas",
    "San Sebastián",
    "Santander de Quilichao",
    "Silvia",
    "Sotará",
    "Suárez",
    "Sucre",
    "Timbío",
    "Timbiquí",
    "Toribío",
    "Totoró",
    "Villa Rica",
    "Aguachica",
    "Agustín Codazzi",
    "Astrea",
    "Becerril",
    "Bosconia",
    "Chimichagua",
    "Chiriguaná",
    "Curumaní",
    "El Copey",
    "El Paso",
    "Gamarra",
    "González",
    "La Gloria (Cesar)",
    "La Jagua de Ibirico",
    "La Paz",
    "Manaure Balcón del Cesar",
    "Pailitas",
    "Pelaya",
    "Pueblo Bello",
    "Río de Oro",
    "San Alberto",
    "San Diego",
    "San Martín",
    "Tamalameque",
    "Valledupar",
    "Acandí",
    "Alto Baudó",
    "Bagadó",
    "Bahía Solano",
    "Bajo Baudó",
    "Bojayá",
    "Cantón de San Pablo",
    "Cértegui",
    "Condoto",
    "El Atrato",
    "El Carmen de Atrato",
    "El Carmen del Darién",
    "Istmina",
    "Juradó",
    "Litoral de San Juan",
    "Lloró",
    "Medio Atrato",
    "Medio Baudó",
    "Medio San Juan",
    "Nóvita",
    "Nuquí",
    "Quibdó",
    "Río Iró",
    "Río Quito",
    "San José del Palmar",
    "Sipí",
    "Tadó",
    "Unión Panamericana",
    "Unguía",
    "Agua de Dios",
    "Albán",
    "Anapoima",
    "Anolaima",
    "Apulo",
    "Arbeláez",
    "Beltrán",
    "Bituima",
    "Bogotá",
    "Bojacá",
    "Cabrera",
    "Cachipay",
    "Cajicá",
    "Caparrapí",
    "Cáqueza",
    "Carmen de Carupa",
    "Chaguaní",
    "Chía",
    "Chipaque",
    "Choachí",
    "Chocontá",
    "Cogua",
    "Cota",
    "Cucunubá",
    "El Colegio",
    "El Rosal",
    "Facatativá",
    "Fómeque",
    "Fosca",
    "Funza",
    "Fúquene",
    "Fusagasugá",
    "Gachalá",
    "Gachancipá",
    "Gachetá",
    "Gama",
    "Girardot",
    "Granada",
    "Guachetá",
    "Guaduas",
    "Guasca",
    "Guataquí",
    "Guatavita",
    "Guayabal de Síquima",
    "Guayabetal",
    "Gutiérrez",
    "Jerusalén",
    "Junín",
    "La Calera",
    "La Mesa",
    "La Palma",
    "La Peña",
    "Lenguazaque",
    "Machetá",
    "Madrid",
    "Manta",
    "Medina",
    "Mosquera",
    "Nemocón",
    "Nilo",
    "Nimaima",
    "Nocaima",
    "Pacho",
    "Paime",
    "Pandi",
    "Paratebueno",
    "Pasca",
    "Puerto Salgar",
    "Pulí",
    "Quebradanegra",
    "Quetame",
    "Quipile",
    "Ricaurte",
    "San Antonio del Tequendama",
    "San Bernardo",
    "San Cayetano",
    "San Juan de Rioseco",
    "Sasaima",
    "Sesquilé",
    "Sibaté",
    "Silvania",
    "Simijaca",
    "Soacha",
    "Sopó",
    "Subachoque",
    "Suesca",
    "Supatá",
    "Susa",
    "Sutatausa",
    "Tabio",
    "Tausa",
    "Tena",
    "Tenjo",
    "Tibacuy",
    "Tibirita",
    "Tocaima",
    "Tocancipá",
    "Topaipí",
    "Ubalá",
    "Ubaque",
    "Ubaté",
    "Une",
    "Útica",
    "Vergara",
    "Vianí",
    "Villagómez",
    "Villapinzón",
    "Villeta",
    "Viotá",
    "Yacopí",
    "Zipacón",
    "Zipaquirá",
    "Ayapel",
    "Canalete",
    "Cereté",
    "Chimá",
    "Chinú",
    "Ciénaga de Oro",
    "Cotorra",
    "La Apartada",
    "Lorica",
    "Los Córdobas",
    "Momil",
    "Montelíbano",
    "Montería",
    "Moñitos",
    "Planeta Rica",
    "Pueblo Nuevo",
    "Puerto Escondido",
    "Puerto Libertador",
    "Purísima",
    "Sahagún",
    "San Andrés de Sotavento",
    "San Antero",
    "San Bernardo del Viento",
    "San José de Uré",
    "San Pelayo",
    "Tierralta",
    "Tuchín",
    "Valencia",
    "Inírida",
    "El Retorno",
    "San José del Guaviare",
    "Acevedo",
    "Agrado",
    "Aipe",
    "Algeciras",
    "Altamira",
    "Baraya",
    "Campoalegre",
    "Colombia",
    "El Pital",
    "Elías",
    "Garzón",
    "Gigante",
    "Hobo",
    "Íquira",
    "Isnos",
    "La Argentina",
    "La Plata",
    "Nátaga",
    "Neiva",
    "Oporapa",
    "Paicol",
    "Palermo",
    "Pitalito",
    "Rivera",
    "Saladoblanco",
    "San Agustín",
    "Suaza",
    "Tarqui",
    "Tello",
    "Teruel",
    "Tesalia",
    "Timaná",
    "Villavieja",
    "Yaguará",
    "Barrancas",
    "Dibulla",
    "Distracción",
    "El Molino",
    "Fonseca",
    "Hatonuevo",
    "La Jagua del Pilar",
    "Maicao",
    "Manaure",
    "Riohacha",
    "San Juan del Cesar",
    "Uribia",
    "Urumita",
    "Algarrobo",
    "Aracataca",
    "Ariguaní",
    "Cerro de San Antonio",
    "Chibolo",
    "Ciénaga",
    "El Banco",
    "El Piñón",
    "El Retén",
    "Fundación",
    "Guamal",
    "Nueva Granada",
    "Pedraza",
    "Pijiño del Carmen",
    "Pivijay",
    "Plato",
    "Pueblo Viejo",
    "Remolino",
    "Sabanas de San Ángel",
    "San Sebastián de Buenavista",
    "San Zenón",
    "Santa Ana",
    "Santa Bárbara de Pinto",
    "Santa Marta",
    "Sitionuevo",
    "Tenerife",
    "Zapayán",
    "Zona Bananera",
    "Acacías",
    "Barranca de Upía",
    "Cabuyaro",
    "Castilla la Nueva",
    "Cubarral",
    "Cumaral",
    "El Calvario",
    "El Castillo",
    "El Dorado",
    "Fuente de Oro",
    "La Macarena",
    "La Uribe",
    "Lejanías",
    "Mapiripán",
    "Mesetas",
    "Puerto Concordia",
    "Puerto Gaitán",
    "Puerto Lleras",
    "Puerto López",
    "Puerto Rico",
    "Restrepo",
    "San Carlos de Guaroa",
    "San Juan de Arama",
    "San Juanito",
    "Villavicencio",
    "Vista Hermosa",
    "Aldana",
    "Ancuyá",
    "Arboleda",
    "Barbacoas",
    "Buesaco",
    "Chachagüí",
    "Colón",
    "Consacá",
    "Contadero",
    "Cuaspud",
    "Cumbal",
    "Cumbitara",
    "El Charco",
    "El Rosario",
    "El Tablón",
    "Francisco Pizarro",
    "Funes",
    "Guachucal",
    "Guaitarilla",
    "Gualmatán",
    "Iles",
    "Imués",
    "Ipiales",
    "La Cruz",
    "La Florida",
    "La Llanada",
    "La Tola",
    "Leiva",
    "Linares",
    "Los Andes",
    "Magüí Payán",
    "Mallama",
    "Olaya Herrera",
    "Ospina",
    "Pasto",
    "Policarpa",
    "Potosí",
    "Providencia",
    "Puerres",
    "Pupiales",
    "Roberto Payán",
    "Samaniego",
    "San José de Albán",
    "San Lorenzo",
    "San Pedro de Cartago",
    "Sandoná",
    "Santacruz",
    "Sapuyes",
    "Taminango",
    "Tangua",
    "Tumaco",
    "Túquerres",
    "Yacuanquer",
    "Ábrego",
    "Arboledas",
    "Bochalema",
    "Bucarasica",
    "Cáchira",
    "Cácota",
    "Chinácota",
    "Chitagá",
    "Convención",
    "Cúcuta",
    "Cucutilla",
    "Duranía",
    "El Carmen",
    "El Tarra",
    "El Zulia",
    "Gramalote",
    "Hacarí",
    "Herrán",
    "La Esperanza",
    "La Playa de Belén",
    "Labateca",
    "Los Patios",
    "Lourdes",
    "Mutiscua",
    "Ocaña",
    "Pamplona",
    "Pamplonita",
    "Puerto Santander",
    "Ragonvalia",
    "Salazar de Las Palmas",
    "San Calixto",
    "Santiago",
    "Santo Domingo de Silos",
    "Sardinata",
    "Teorama",
    "Tibú",
    "Villa Caro",
    "Villa del Rosario",
    "Mocoa",
    "Orito",
    "Puerto Asís",
    "Puerto Caicedo",
    "Puerto Guzmán",
    "Puerto Leguízamo",
    "San Miguel",
    "Sibundoy",
    "Valle del Guamuez",
    "Villagarzón",
    "Calarcá",
    "Circasia",
    "Filandia",
    "Génova",
    "La Tebaida",
    "Montenegro",
    "Pijao",
    "Quimbaya",
    "Salento",
    "Apía",
    "Belén de Umbría",
    "Dosquebradas",
    "Guática",
    "La Celia",
    "La Virginia",
    "Marsella",
    "Mistrató",
    "Pereira",
    "Quinchía",
    "Santa Rosa de Cabal",
    "Santuario",
    "Providencia y Santa Catalina Islas",
    "San Andrés",
    "Aguada",
    "Aratoca",
    "Barichara",
    "Barrancabermeja",
    "Bucaramanga",
    "California",
    "Capitanejo",
    "Carcasí",
    "Cepitá",
    "Cerrito",
    "Charalá",
    "Charta",
    "Chima",
    "Chipatá",
    "Cimitarra",
    "Confines",
    "Contratación",
    "Coromoro",
    "Curití",
    "El Carmen de Chucurí",
    "El Guacamayo",
    "El Playón",
    "El Socorro",
    "Encino",
    "Enciso",
    "Florián",
    "Floridablanca",
    "Galán",
    "Gámbita",
    "Girón",
    "Guaca",
    "Guapotá",
    "Guavatá",
    "Güepsa",
    "Hato",
    "Jesús María",
    "Jordán",
    "La Belleza",
    "Landázuri",
    "Lebrija",
    "Los Santos",
    "Macaravita",
    "Málaga",
    "Matanza",
    "Mogotes",
    "Molagavita",
    "Ocamonte",
    "Oiba",
    "Onzaga",
    "Palmar",
    "Palmas del Socorro",
    "Páramo",
    "Piedecuesta",
    "Pinchote",
    "Puente Nacional",
    "Puerto Parra",
    "Puerto Wilches",
    "Sabana de Torres",
    "San Benito",
    "San Gil",
    "San Joaquín",
    "San José de Miranda",
    "San Vicente de Chucurí",
    "Santa Bárbara",
    "Santa Helena del Opón",
    "Simacota",
    "Suaita",
    "Suratá",
    "Tona",
    "Valle de San José",
    "Vélez",
    "Vetas",
    "Zapatoca",
    "Caimito",
    "Chalán",
    "Colosó",
    "Corozal",
    "Coveñas",
    "El Roble",
    "Galeras",
    "Guaranda",
    "Los Palmitos",
    "Majagual",
    "Morroa",
    "Ovejas",
    "Sampués",
    "San Antonio de Palmito",
    "San Benito Abad",
    "San Juan de Betulia",
    "San Marcos",
    "San Onofre",
    "San Pedro",
    "Sincé",
    "Sincelejo",
    "Tolú",
    "Tolú Viejo",
    "Alpujarra",
    "Alvarado",
    "Ambalema",
    "Anzoátegui",
    "Armero",
    "Ataco",
    "Cajamarca",
    "Carmen de Apicalá",
    "Casabianca",
    "Chaparral",
    "Coello",
    "Coyaima",
    "Cunday",
    "Dolores",
    "El Espinal",
    "Falán",
    "Flandes",
    "Fresno",
    "Guamo",
    "Herveo",
    "Honda",
    "Ibagué",
    "Icononzo",
    "Lérida",
    "Líbano",
    "Mariquita",
    "Melgar",
    "Murillo",
    "Natagaima",
    "Ortega",
    "Palocabildo",
    "Piedras",
    "Planadas",
    "Prado",
    "Purificación",
    "Rioblanco",
    "Roncesvalles",
    "Rovira",
    "Saldaña",
    "San Antonio",
    "Santa Isabel",
    "Valle de San Juan",
    "Venadillo",
    "Villahermosa",
    "Villarrica",
    "Alcalá",
    "Andalucía",
    "Ansermanuevo",
    "Argelia",
    "Buenaventura",
    "Buga",
    "Bugalagrande",
    "Caicedonia",
    "Cali",
    "Calima",
    "Cartago",
    "Dagua",
    "El Águila",
    "El Cairo",
    "El Cerrito",
    "El Dovio",
    "Florida",
    "Ginebra",
    "Guacarí",
    "Jamundí",
    "La Cumbre",
    "Obando",
    "Palmira",
    "Pradera",  
    "Riofrío",
    "Roldanillo",
    "Sevilla",
    "Toro",
    "Trujillo",
    "Tuluá",
    "Ulloa",
    "Versalles",
    "Vijes",
    "Yotoco",
    "Yumbo",
    "Zarzal",
    "Carurú",
    "Mitú",
    "Taraira",
    "Cumaribo",
    "La Primavera",
    "Puerto Carreño",
    "Santa Rosalía"
  ]

  constructor(
    private utilsService: UtilsService
  ) { }

  filterCities(value: string): string[] {
    return ( value.trim() === '' ) 
    ? [] 
    : this.citiesColombia.filter( city => {
      const cityDiacriticsRemoved  = this.utilsService.removeDiacritics( city.toLowerCase() ); 
      const valueDiacriticsRemoved = this.utilsService.removeDiacritics( value.toLowerCase() );
      if ( cityDiacriticsRemoved === valueDiacriticsRemoved ) [];
      else { return cityDiacriticsRemoved.includes( valueDiacriticsRemoved ) } 
    });
  }

  validateEventCategory( categoriesValid: string[] ) {
    return ( control: FormControl ) => {
      return ( categoriesValid.includes(control.value) ) ? null : { categoryInvalid: true } 
    }
  }

  validateEventAudience( audienceValid: string[] ) {
    return ( control: FormControl ) => {
      return ( audienceValid.includes(control.value) ) ? null : { audienceInvalid: true } 
    }
  }

  validateThatThePropertyIsNotJustBlanks( control: AbstractControl ) {
      return ( control.value.trim().length === 0 && control.value !== '' ) ? { propertyBlank : true } : null;
  }

  validateThatThePropertyIsNotJustBlanksItHasBeenTouched( control: AbstractControl ) {
    return ( control.value.length > 0 && control.value.trim().length === 0 ) ? { properyBlankIfTouched: true } : null;  
  }


}
