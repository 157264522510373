import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ResponseLogin } from '@core/interfaces/user/response-login';
import { HandleUserStorageService } from '@core/services/user/handle-storage.service';

@Injectable({
    providedIn: 'root'
})

export class LoginService {

    private URLBACKEND = environment.urlBackend;

    constructor(
        private http: HttpClient,
        private handleUserStorageService: HandleUserStorageService
    ){}

    loginDayvents( dataUser: { email: string, password: string } ): Observable<ResponseLogin> {

        return this.http.post(`${ this.URLBACKEND }/login-dayvents`, dataUser)
                        .pipe( map( (response: ResponseLogin ) =>  {
                            
                                        if ( response.ok ) {
                                                this.handleUserStorageService.setToken( response.Authorization, 'Authorization' );
                                        }

                                        return response;
                        }) );

    }

    loginFacebook( { id: id_facebook, first_name, last_name, email, gender, location } ) {
        return this.http.post( `${ this.URLBACKEND }/login-facebook`, { id_facebook, first_name, last_name, email, gender, location } )

                        .pipe( map( ( response: ResponseLogin ) =>  {
                                    
                                if ( response.ok ) {
                                        this.handleUserStorageService.setToken( response.Authorization, 'Authorization' );
                                }

                                return response;
                        }) );

    }

    loginGoogle( { given_name, name, email, location } ){
        return this.http.post( `${ this.URLBACKEND }/login-google`, { given_name, name, email, location } )
                        .pipe( map( ( response: ResponseLogin ) =>  {
                                            
                                if ( response.ok ) {
                                        this.handleUserStorageService.setToken( response.Authorization, 'Authorization' );
                                }

                                return response;
                        }) );
    }



}

