<div id="container-principal">

     <!-- icon -->
        <div class="icon-calendar">
            <img src="https://dayvents.com/assets/icons/logo-dayvents.svg" alt="logo-dayvents.svg">  
            <span class="title-dayvents">Dayvents</span>
        </div>

        <form class="form-email" [formGroup]="formForgotPassword" (ngSubmit)="sendEmail()">
            
            <p class="container-sentence">
                Te enviaremos a tu correo un enlace para que puedas cambiar la contraseña
            </p>

            <div class="container-form-field-email">

                <mat-form-field appearance="outline">
                  <mat-label>Ingresa tu correo</mat-label>
                  <input matInput
                         appTrimBlackInput 
                         type="text"
                         formControlName="email"
                         id="input-email"
                         (keyup.enter)=" keyupEnterInputEmail() ">

                         <mat-error *ngIf="formValidators('email').errors?.required &&
                                            submitFormForgotPassword === true">
                             Debes ingresar tu correo
                         </mat-error>

                         <mat-error *ngIf="formValidators('email').errors?.pattern">
                             El correo ingresado no es valido
                         </mat-error>
                </mat-form-field>
    
            </div>

            <button mat-raised-button 
                    class="btn-email" 
                    color="primary"
                    [disabled]="loadingBtnLogin">
                <span *ngIf="!loadingBtnLogin">Enviar</span>

                <app-loading-circle 
                    [ngStyle]="{ 'display': 'inline-block' }"
                    [diameter]="20"
                    [color]="'#9DADB8'"
                    *ngIf="loadingBtnLogin">
                </app-loading-circle>
                <span *ngIf="loadingBtnLogin">Enviando</span>
                
            </button>

        </form>

        <button mat-stroked-button 
                color="primary" 
                class="btn-back" 
                (click)="redirect('/')">
                <span>Iniciar sesión</span>
        </button>


</div>
