import { Component, OnInit } from '@angular/core';

import { UtilsService } from '@core/services/utils/utils.service';

@Component({
  selector: 'app-internet-error',
  templateUrl: './internet-error.component.html',
  styleUrls: ['./internet-error.component.scss']
})
export class InternetErrorComponent implements OnInit {

  error: string;

  constructor(
    private utilsService: UtilsService
  ) { }

  async ngOnInit() {

    const network = await this.utilsService.checkNetwork();
    this.error = network ? 'Parece que ha ocurrido un error inesperado' : 'Verifica tu conexión a internet'; 
    // if ( !window.history.state?.error ) this.router.navigateByUrl('/not-found');

  }

  reload() {
    this.utilsService.routeBack();

  }

}
