import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

import { filter, pluck } from 'rxjs/operators';

import { HandleUserStorageService } from '@core/services/user/handle-storage.service';
import { HandleSnackbarService } from '@core/services/snackbars/handle-snackbars.service';
import { HandleGlobalModalsService } from '@core/services/modals/handle-global-modals.service';
import { AuthFacebookService } from '@core/services/user/auth/auth-facebook.service';
import { AuthGoogleService } from '@core/services/user/auth/auth-google.service';
import { UtilsService } from '@core/services/utils/utils.service';
import { LoginService } from '@core/services/user/auth/login.service';

@Component({
  selector: 'app-login-facebook-google',
  templateUrl: './login-facebook-google.component.html',
  styleUrls: ['./login-facebook-google.component.scss']
})
export class LoginFacebookGoogleComponent implements OnInit {

  facebookLoginUrl: string;
  googleLoginUrl: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authFacebookService: AuthFacebookService,
    private authGoogleService: AuthGoogleService,
    private handleUserStorageService: HandleUserStorageService,
    private handleGlobalModalsService: HandleGlobalModalsService,
    private handleSnackbarService: HandleSnackbarService,
    private utilsService: UtilsService,
    private loginService: LoginService,
    private router: Router 
  ) { }

  ngOnInit(): void {

    this.activatedRoute.queryParams
    .pipe( 
      filter( params => params.code?.length ),
      pluck( 'code' ) 
    )
    .subscribe( async code => {

        // get user login platform
        const platformLogin = this.handleUserStorageService.getPlatformLogin();

        const location = await this.getLocation();

        // error google or facebook login
        const error = { error: { message: 'Ha ocurrido un error inesperado, inténtalo de nuevo en un momento' } };

        if ( platformLogin === 'facebook' ) this.verifyFacebookLogin( code,  location, error );
        else if ( platformLogin === 'google' ) this.verifyGoogleLogin( code, location,  error );
        else this.throwModalError(error);

     });

  }

  async loginFacebook( login: string ) {
    this.facebookLoginUrl = await this.authFacebookService.loadQueryFacebook( login );
    window.location.href = this.facebookLoginUrl;
  }

  async verifyFacebookLogin( code: string, location: string, error: any ) {

    this.handleSnackbarService.snackbarLoading();

    const access_token = await this.authFacebookService.getAccessTokenFromCodeFacebook( code );
    const dataUser = await this.authFacebookService.getFacebookUserData( access_token );

    if ( dataUser.error ) {

        this.handleSnackbarService.closeSnackbar();
        this.throwModalError( new HttpErrorResponse( error ) );
        this.router.navigateByUrl('/');

    } else { 

        this.loginService.loginFacebook( { ...dataUser, location } ).subscribe( 
          () => {
            this.handleSnackbarService.closeSnackbar();
            this.router.navigateByUrl('/explore');
          },
          err => {
            this.handleSnackbarService.closeSnackbar();
            this.throwModalError( err );
            this.router.navigateByUrl('/');
          }
        );

    }

  }

  async verifyGoogleLogin( code: string, location: string, error: any ) {

    this.handleSnackbarService.snackbarLoading();

    const access_token = await this.authGoogleService.getAccessTokenFromCodeGoogle( code );
    const dataUser = await this.authGoogleService.getGoogleUserData( access_token );

    if ( dataUser.error ) {

        this.handleSnackbarService.closeSnackbar();
        this.throwModalError( new HttpErrorResponse( error ) );
        this.router.navigateByUrl('/');

    } else {

        this.loginService.loginGoogle( { ...dataUser, location } ).subscribe(
          () => {
            this.handleSnackbarService.closeSnackbar();
            this.router.navigateByUrl('/explore');
          },
          err => {
            this.handleSnackbarService.closeSnackbar();
            this.throwModalError( err );
            this.router.navigateByUrl('/');
          }
        );

    }

  }

  async loginGoogle( login: string ) {
    this.googleLoginUrl = await this.authGoogleService.loadQueyGoogle( login );
    window.location.href = this.googleLoginUrl;
  }

  private throwModalError( error: any ) {
    this.handleGlobalModalsService.modalError( error );
  }

  // get location user
  private async getLocation(): Promise<string> {
    // const location = await this.utilsService.getLocation();
    // return ( !location ) ? 'puerto berrio' : location.city;
    return 'puerto berrio';
  }

}
