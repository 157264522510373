import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { AngularMaterialModule } from '@shared/angular-material/angular-material.module';

import { ErrorComponent } from '@shared/modals/error/error.component';
import { SuccessComponent } from '@shared/modals/success/success.component';
import { UploadImageUserComponent } from '@shared/modals/user/upload-image-user/upload-image-user.component';
import { FilterEventsComponent } from '@shared/modals/event/filter-events/filter-events.component';
import { ShareEventComponent } from '@shared/modals/event/share-event/share-event.component';
import { EventStatsComponent } from '@shared/modals/event/event-stats/event-stats.component';
import { DeleteEventComponent } from '@shared/modals/event/delete-event/delete-event.component';
import { PauseOrEnableEventComponent } from '@shared/modals/event/pause-or-enable-event/pause-or-enable-event.component';
import { UsersFollowersComponent } from '@shared/modals/user/users-followers/users-followers.component';
import { UsersAssistantsLikesFollowersComponent } from '@shared/modals/user/users-assistants-likes-followers/users-assistants-likes-followers/users-assistants-likes-followers.component';
import { TopEventsPreviousAndCurrentCityComponent } from '@shared/modals/event/top-events/top-events-previous-and-current-city/top-events-previous-and-current-city.component';
import { TopEventsPreviousAndCurrentGlobalComponent } from '@shared/modals/event/top-events/top-events-previous-and-current-global/top-events-previous-and-current-global.component';
import { EventsOfUserComponent } from '@shared/modals/event/events-of-user/events-of-user.component';
import { FollowersEventsAndUsersComponent } from '@shared/modals/followers-events-and-users/followers-events-and-users.component';
import { DeleteUserMessagesComponent } from '@shared/modals/user-messages/delete-user-messages/delete-user-messages.component'; 
import { ChangePasswordComponent } from '@shared/modals/user/change-password/change-password.component';
import { EventsPreferenceComponent } from '@shared/modals/user/events-preference/events-preference.component';
import { RequestPushNotificationsComponent } from '@shared/modals/user/push-notifications/request-push-notifications/request-push-notifications.component';
import { ReportedEventComponent } from '@shared/modals/event/reported-event/reported-event.component';
import { NotificationsComponent } from '@shared/modals/user/notifications/notifications.component';
import { NotificationsPermissionDeniedComponent } from '@shared/modals/user/push-notifications/notifications-permission-denied/notifications-permission-denied.component';
import { RecommendedEventComponent } from '@shared/modals/user/recommended-event/recommended-event.component';
import { ExitCreateEventComponent } from '@shared/modals/event/exit-create-event/exit-create-event.component';
import { UserSendMessageComponent } from '@shared/modals/user/user-send-message/user-send-message.component';
import { EventHourClockComponent } from '@shared/modals/event/event-hour-clock/event-hour-clock.component';

import { FormLoginComponent } from '@shared/components/forms/form-login/form-login.component';
import { FormRegisterComponent } from '@shared/components/forms/form-register/form-register.component';
import { FormForgotPasswordComponent } from '@shared/components/forms/form-forgot-password/form-forgot-password.component';
import { FormChangePasswordComponent } from '@shared/components/forms/form-change-password/form-change-password.component';

import { ImageUserPipe } from '@shared/pipes/user/image-user.pipe';
import { ImageUploadUserPipe } from '@shared/pipes/user/image-upload-user.pipe';
import { FileEventPipe } from '@shared/pipes/event/file-event.pipe';
import { EventCostPipe } from '@shared/pipes/event/event-cost.pipe'; 
import { ReplaceMoreThanTwoLineBreakPipe } from '@shared/pipes/replace-more-than-two-line-break.pipe';
import { NumbersFormatAssistantsLikesFollowersPipe } from '@shared/pipes/numbers-format-assistants-likes-followers.pipe';
import { ReplaceTextErrorTwoEventFilteredPipe } from '@shared/pipes/event/replace-text-error-two-event-filtered.pipe';
import { TimeagoPipe } from '@shared/pipes/timeago.pipe';
import { RemoveBannedUserPipe } from '@shared/pipes/remove-banned-user.pipe';
import { CustomLetteringSlicePipe } from '@shared/pipes/custom-lettering-slice.pipe';
import { IdentifyLineBreakAndTrimPipe } from '@shared/pipes/event/identify-line-break-and-trim.pipe';

import { FocusInputCustomedDirective } from '@shared/directives/focus-input-customed.directive';
import { ViewStatsIconEventDirective } from '@shared/directives/view-stats-icon-event.directive';
import { LazyloadFileDirective } from '@shared/directives/lazyload-file.directive';
import { ViewOptionsForTheEventPlusIconDirective } from '@shared/directives/view-options-for-the event-plus-icon.directive';
import { CustomMaximumLengthInputDirective } from '@shared/directives/custom-maximum-length-input.directive';
import { TrimBlackInputDirective } from '@shared/directives/trim-blank-input.directive';
import { DetectUserConnectedDirective } from '@shared/directives/detect-user-connected.directive';

import { FooterOneComponent } from '@shared/components/footer-one/footer-one.component';
import { LoginFacebookGoogleComponent } from '@shared/components/login-facebook-google/login-facebook-google.component';
import { HeaderExploreComponent } from '@shared/components/explore/header-explore/header-explore.component';
import { SidebarExploreComponent } from '@shared/components/explore/sidebar-explore/sidebar-explore.component';
import { EventsSearchInputComponent } from '@shared/components/explore/events/events-search-input/events-search-input.component';
import { EventsListComponent } from '@shared/components/explore/events/events-list/events-list.component';
import { HeaderModalsComponent } from '@shared/components/header-modals/header-modals.component';
import { NotFoundEventOrUserComponent } from '@shared/components/404/not-found-event-or-user/not-found-event-or-user.component';
import { EventsMoreRelevantListComponent } from '@shared/components/explore/events/events-more-relevant-list/events-more-relevant-list.component';
import { PeopleSearchInputComponent } from '@shared/components/explore/people/people-search-input/people-search-input.component';
import { PeopleListComponent } from '@shared/components/explore/people/people-list/people-list.component';
import { UserMessagesListComponent } from '@shared/components/explore/user-messages/user-messages-list/user-messages-list.component';
import { UserMessagesSearchInputComponent } from '@shared/components/explore/user-messages/user-messages-search-input/user-messages-search-input.component';
import { UserMessageComponent } from '@shared/components/explore/user-messages/user-message/user-message.component';
import { LoadingSnackbarComponent } from '@shared/components/loadings/loading-snackbar/loading-snackbar.component';
import { LoadingCircleComponent } from '@shared/components/loadings/loading-circle/loading-circle.component';
import { ImageClippingComponent } from '@shared/components/explore/utils/image-clipping/image-clipping.component';
import { HeaderInformationComponent } from '@shared/components/information/header-information/header-information.component';

@NgModule({
    declarations: [
        FooterOneComponent,
        ErrorComponent,
        SuccessComponent,
        LoginFacebookGoogleComponent,
        FormLoginComponent,
        FormRegisterComponent,
        FormForgotPasswordComponent,
        FormChangePasswordComponent,
        HeaderExploreComponent,
        SidebarExploreComponent,
        UploadImageUserComponent,
        ImageUserPipe,
        ImageUploadUserPipe,
        FileEventPipe,
        EventCostPipe,
        TimeagoPipe,
        ReplaceMoreThanTwoLineBreakPipe,
        NumbersFormatAssistantsLikesFollowersPipe,
        RemoveBannedUserPipe,
        CustomLetteringSlicePipe,
        FilterEventsComponent,
        ShareEventComponent,
        FocusInputCustomedDirective,
        ViewStatsIconEventDirective,
        LazyloadFileDirective,
        ViewOptionsForTheEventPlusIconDirective,
        EventsSearchInputComponent,
        EventsListComponent,
        EventStatsComponent,
        HeaderModalsComponent,
        NotFoundEventOrUserComponent,
        ReplaceTextErrorTwoEventFilteredPipe,
        EventsMoreRelevantListComponent,
        DeleteEventComponent,
        PauseOrEnableEventComponent,
        DeleteUserMessagesComponent,
        PeopleSearchInputComponent,
        PeopleListComponent,
        UsersFollowersComponent,
        UsersAssistantsLikesFollowersComponent,
        TopEventsPreviousAndCurrentCityComponent,
        TopEventsPreviousAndCurrentGlobalComponent,
        EventsOfUserComponent,
        FollowersEventsAndUsersComponent,
        UserMessagesListComponent,
        UserMessagesSearchInputComponent,
        UserMessageComponent,
        CustomMaximumLengthInputDirective,
        TrimBlackInputDirective,
        DetectUserConnectedDirective,
        IdentifyLineBreakAndTrimPipe,
        ChangePasswordComponent,
        EventsPreferenceComponent,
        LoadingSnackbarComponent,
        LoadingCircleComponent,
        RequestPushNotificationsComponent,
        ImageClippingComponent,
        ReportedEventComponent,
        NotificationsComponent,
        NotificationsPermissionDeniedComponent,
        RecommendedEventComponent,
        HeaderInformationComponent,
        ExitCreateEventComponent,
        UserSendMessageComponent,
        EventHourClockComponent
    ],
    imports: [ 
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        AngularMaterialModule
    ],
    exports: [
        AngularMaterialModule,
        FooterOneComponent,
        ErrorComponent,
        SuccessComponent,
        LoginFacebookGoogleComponent,
        FormLoginComponent,
        FormRegisterComponent,
        FormForgotPasswordComponent,
        FormChangePasswordComponent,
        HeaderExploreComponent,
        SidebarExploreComponent,
        UploadImageUserComponent,
        ImageUserPipe,
        ImageUploadUserPipe,
        FileEventPipe,
        EventCostPipe,
        TimeagoPipe,
        ReplaceMoreThanTwoLineBreakPipe,
        NumbersFormatAssistantsLikesFollowersPipe,
        RemoveBannedUserPipe,
        CustomLetteringSlicePipe,
        IdentifyLineBreakAndTrimPipe,
        FilterEventsComponent,
        ShareEventComponent,
        FocusInputCustomedDirective,
        ViewStatsIconEventDirective,
        LazyloadFileDirective,
        ViewOptionsForTheEventPlusIconDirective,
        EventsSearchInputComponent,
        EventsListComponent,
        EventStatsComponent,
        HeaderModalsComponent,
        NotFoundEventOrUserComponent,
        ReplaceTextErrorTwoEventFilteredPipe,
        EventsMoreRelevantListComponent,
        DeleteEventComponent,
        PauseOrEnableEventComponent,
        DeleteUserMessagesComponent,
        PeopleSearchInputComponent,
        PeopleListComponent,
        UsersFollowersComponent,
        UsersAssistantsLikesFollowersComponent,
        TopEventsPreviousAndCurrentCityComponent,
        EventsOfUserComponent,
        FollowersEventsAndUsersComponent,
        UserMessagesListComponent,
        UserMessagesSearchInputComponent,
        UserMessageComponent,
        CustomMaximumLengthInputDirective,
        TrimBlackInputDirective,
        DetectUserConnectedDirective,
        ChangePasswordComponent,
        EventsPreferenceComponent,
        LoadingSnackbarComponent,
        LoadingCircleComponent,
        RequestPushNotificationsComponent,
        ImageClippingComponent,
        ReportedEventComponent,
        NotificationsComponent,
        NotificationsPermissionDeniedComponent,
        RecommendedEventComponent,
        HeaderInformationComponent,
        ExitCreateEventComponent,
        UserSendMessageComponent,
        EventHourClockComponent
    ],
})
export class SharedModule {}