import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ModulesPreloadStrategyService } from '@core/services/utils/modules-preload-strategy.service';

// pages
import { LoginComponent } from '@pages/login/login.component';
import { RegisterComponent } from '@pages/register/register.component';
import { ForgotPasswordComponent } from '@pages/forgot-password/forgot-password.component';
import { ChangeForgotPasswordComponent } from '@pages/change-forgot-password/change-forgot-password.component';
import { InternetErrorComponent } from '@explore/internet-error/internet-error.component'; 

// guards
import { CheckUserNotLoginGuard } from '@core/services/guards/can-activate/check-user-not-login.guard';

const routes: Routes = [
    { path: '',                       component:    LoginComponent,
                                      canActivate:  [ CheckUserNotLoginGuard ] },

    { path: 'login',                  component:    LoginComponent,
                                      canActivate:  [ CheckUserNotLoginGuard ] },
          
    { path: 'register',               component:    RegisterComponent,
                                      canActivate:  [ CheckUserNotLoginGuard ] },
       
    { path: 'forgot/password',        component:    ForgotPasswordComponent,
                                      canActivate:  [ CheckUserNotLoginGuard ] },

    { path: 'change/password/:token', component:    ChangeForgotPasswordComponent,
                                      canActivate:  [ CheckUserNotLoginGuard ] },

    { path: 'explore',                loadChildren: () => import('@explore/explore.module').then(m => m.ExploreModule), 
                                      data: { preload: false }
    },
    
    { path: 'information',            loadChildren: () => import('@information/information.module').then(m => m.InformationModule),
                                      data: { preload: true } 
    },

    { path: 'error',                  component:    InternetErrorComponent },
    
    { path: '**',                     pathMatch:    'full', redirectTo: '' }
];

@NgModule({
    imports: [  RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    preloadingStrategy: ModulesPreloadStrategyService,
    initialNavigation: 'enabledBlocking'
})],
    exports: [RouterModule]
})

export class AppRoutingModule {}
