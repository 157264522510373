import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';

import { Observable } from 'rxjs';

import { UserModel } from '@core/interfaces/user/user';
import { ResponseUserBasic } from '@core/interfaces/user/response-user-basic';
import { ResponseLogin } from '@core/interfaces/user/response-login';
import { HandleUserStorageService } from '@core/services/user/handle-storage.service';
import { HandleSnackbarService } from '@core/services/snackbars/handle-snackbars.service';


@Injectable({
    providedIn: 'root'
})

export class RegisterUserService {

    private URLBACKEND = environment.urlBackend;

    constructor(
        private http: HttpClient,
        private router: Router,
        private handleUserStorageService: HandleUserStorageService,
        private handleSnackbarService: HandleSnackbarService
    ){}


    registerUser( data: Partial<UserModel> ): Observable<ResponseUserBasic> {
        return this.http.post<ResponseUserBasic>(`${ this.URLBACKEND }/users-register`, data);

    }

    verifyAccount( token: string ) {
        this.http.put(`${ this.URLBACKEND }/users-verify-account-token`, { token })
                 .subscribe( 
                     ( response: ResponseLogin ) => {

                        this.handleSnackbarService.closeSnackbar();
                         
                         if ( response.ok === true ) {
                                this.handleUserStorageService.setToken( response.Authorization, 'Authorization' );

                                // redirect to events main page
                                this.router.navigateByUrl('/explore');
                         }
                         
                     },
                    () => this.handleSnackbarService.closeSnackbar() 
                 );

    }

}