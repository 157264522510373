
<div id="container-principal">

    <div class="icon-calendar">
        <img src="https://dayvents.com/assets/icons/logo-dayvents.svg" height="52" width="52" alt="logo-dayvents.svg">
    </div>

    <p class="cite">
        <span> 
            "<strong>Dayvents</strong>, 
            ¡Encuentra eventos, amigos y más!".
        </span>
    </p>

    <!-- form login -->
    <form class="form-login" [formGroup]="formLogin" (ngSubmit)="login()">

        <!-- Component login facebook and google -->
        <app-login-facebook-google></app-login-facebook-google>

        <div class="container-form-field-email">

            <mat-form-field appearance="outline">
              <mat-label>Correo</mat-label>
              <input matInput
                     appTrimBlackInput
                     type="text"
                     formControlName="email"
                     placeholder="Ingresa tu correo">
            </mat-form-field>

        </div>

        <div>
            
            <mat-form-field appearance="outline">
              <mat-label>Contraseña</mat-label>
              <input matInput 
                     type="password" 
                     formControlName="password"
                     id="input-password-login"
                     #inputPassword 
                     placeholder="Ingresa tu contraseña" 
                     autocomplete="off">

                <mat-icon class="icon-password-hide" 
                          matSuffix 
                          (click)="viewPassword()"
                          *ngIf="!iconViewPassword">
                            <svg xmlns="http://www.w3.org/2000/svg" height="26px" viewBox="0 0 24 24" width="22px" fill="#000000">
                              <path d="M0 0h24v24H0zm0 0h24v24H0zm0 0h24v24H0zm0 0h24v24H0z" fill="none"/>
                              <path d="M12 7c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C10.74 7.13 11.35 7 12 7zM2 4.27l2.28 2.28.46.46C3.08 8.3 1.78 10.02 1 12c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L19.73 22 21 20.73 3.27 3 2 4.27zM7.53 9.8l1.55 1.55c-.05.21-.08.43-.08.65 0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2zm4.31-.78l3.15 3.15.02-.16c0-1.66-1.34-3-3-3l-.17.01z"/>
                            </svg>
                </mat-icon>
                        
                <mat-icon class="icon-password-view" 
                          matSuffix 
                          (click)="viewPassword()"
                          *ngIf="iconViewPassword">
                           <svg xmlns="http://www.w3.org/2000/svg" height="26px" viewBox="0 0 24 24" width="22"><path d="M0 0h24v24H0z" fill="none"/>
                                <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"/>
                           </svg>
                </mat-icon>

                <!-- errors -->
                <mat-error *ngIf="formValidators('password').errors?.minlength">
                    Mínimo 6 caracteres
                </mat-error>

                <mat-error *ngIf="formValidators('password').errors?.pattern && !formValidators('password').errors?.minlength">
                    La contraseña no es valida
                </mat-error>

            </mat-form-field>

        </div>

        <button class="btn-login" 
                mat-raised-button 
                color="primary"
                [disabled]="formLogin.invalid || loadingBtnLogin">
                <span *ngIf="!loadingBtnLogin">Ingresar</span>

                <app-loading-circle 
                    [ngStyle]="{ 'display': 'inline-block' }"
                    [diameter]="20"
                    [color]="'#9DADB8'"
                    *ngIf="loadingBtnLogin">
                </app-loading-circle>
                <span *ngIf="loadingBtnLogin">Ingresando</span>
                
        </button>

        <p class="forgot-password" (click)="redirect('/forgot/password')">¿Olvidaste tu contraseña?</p>


    </form>

    <button mat-button class="register-account" color="primary" (click)="redirect('/register')">
        Crear cuenta
        <mat-icon class="icon-how_to_reg">
            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                <path d="M0 0h24v24H0z" fill="none" fill-rule="evenodd"/><g fill-rule="evenodd">
                <path d="M9 17l3-2.94c-.39-.04-.68-.06-1-.06-2.67 0-8 1.34-8 4v2h9l-3-3zm2-5c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4"/><path d="M15.47 20.5L12 17l1.4-1.41 2.07 2.08 5.13-5.17 1.4 1.41z"/></g>
            </svg>
        </mat-icon>
    </button>

</div>
