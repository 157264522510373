import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '@environments/environment';

import { filter, pluck } from 'rxjs/operators';

import { ResetPassword } from '@core/services/user/methods/put/reset-password.service';
import { HandleGlobalModalsService } from '@core/services/modals/handle-global-modals.service';
import { UtilsService } from '@core/services/utils/utils.service';

@Component({
  selector: 'app-form-change-password',
  templateUrl: './form-change-password.component.html',
  styleUrls: ['./form-change-password.component.scss']
})
export class FormChangePasswordComponent implements OnInit, AfterViewInit {

  @ViewChild('inputPassword') inputPassword: ElementRef;
  @Output() redirectTo: EventEmitter<string> = new EventEmitter();

  private PRODUCTION = environment.production;
  private tokenForgotPassword: string;

  formChangePassword: FormGroup;
  iconViewPassword: boolean = false;
  submitFormChangePassword: boolean = false;
  loadingBtnChangePassword: boolean = false;
  inputPasswordElement: HTMLElement;

  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private resetPassword: ResetPassword,
    private handleGlobalModalsService: HandleGlobalModalsService,
    private utilsService: UtilsService 
  ) {

    this.formChangePassword = this.fb.group({
      password: ['', [ 
                        Validators.required, 
                        Validators.minLength(6),
                        Validators.pattern(/[a-zA-Z0-9/\W/]{6,}/) 
                     ] 
                ]
    });

   }

  ngOnInit(): void {

    this.activatedRoute.params
    .pipe( 
      filter( param => param.token?.length ),
      pluck('token') 
    )
    .subscribe( token => {
      this.tokenForgotPassword = token;
    });

  }

  ngAfterViewInit() {
    this.inputPasswordElement = document.getElementById('input-password');
  }

  changePassword() {

    this.submitFormChangePassword = true;
    if ( this.formChangePassword.invalid ) return;
    this.loadingBtnChangePassword = true;

    // encrypt pass
    this.formChangePassword.value.password = this.PRODUCTION 
                                             ? this.utilsService.passwordEncrypt( this.formChangePassword.value.password )
                                             : this.formChangePassword.value.password;

    // service for to change password
    this.resetPassword.changeForgotPassword( this.tokenForgotPassword, this.formChangePassword.value.password )
        .subscribe( 
          response => {

            this.loadingBtnChangePassword = false;
            this.handleGlobalModalsService.modalSuccess(response.message, true, '/');

          },
          (err: HttpErrorResponse) => {

            this.loadingBtnChangePassword = false;
            if ( err.status >= 400 && err.status < 500 ) this.handleGlobalModalsService.modalError(err);

          }
        );

  }

  formValidators( field: string ): AbstractControl {
    return this.formChangePassword.get(field);
  }

  // method that changes the type of password input
  viewPassword(): void {

    if ( this.inputPassword.nativeElement.value.length > 0 ) {

        this.iconViewPassword = !this.iconViewPassword;

        ( this.inputPassword.nativeElement.type === 'password' )
        ? this.inputPassword.nativeElement.type = 'text'
        : this.inputPassword.nativeElement.type = 'password';

    }

  }

  redirect( direction: string ) {
    this.redirectTo.emit(direction);
  }

  keyupEnterInputPassword() {
    this.inputPasswordElement.blur();
  }

}