import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

interface Error {
  error: string
}

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent {

  error: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Error
  ) { 
    this.error = this.data.error;
  }

}
