import { Injectable, EventEmitter } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Platform } from '@angular/cdk/platform';
import { Title } from '@angular/platform-browser';
import { environment } from '@environments/environment';

import { AES } from 'crypto-js';
import Compressor from 'compressorjs';

import { Observable, fromEvent, of } from 'rxjs';
import { map, filter, pairwise } from 'rxjs/operators';
import { HasEventTargetAddRemove } from 'rxjs/internal/observable/fromEvent';

import { ResponseIpinfo } from '@core/interfaces/response-ipinfo';
import { ResponseLogin } from '@core/interfaces/user/response-login';
import { HandleUserStorageService } from '@core/services/user/handle-storage.service';
import { DataUserService } from '@core/services/user/methods/get/data.service';
import { EventService } from '@core/services/event/methods/get/event.service';

interface responseScrolling {
    clientHeight: number;
    scrollHeight: number;
    scrollTop: number;
}

@Injectable({
    providedIn: 'root'
})
export class UtilsService {

    private URLBACKEND = environment.urlBackend;
    private previousRoute: string;
    private scroll$: Observable<any>;
    
    scrollEndMatDialogEmitter = new EventEmitter<{ skipData: number, type: 'users' | 'events', time?: 'after' | 'before' }>();
   
    constructor(
        private router: Router,
        private http: HttpClient,
        private platform: Platform,
        private location: Location,
        private titleService: Title,
        private handleUserStorageService: HandleUserStorageService,
        private dataUserService: DataUserService,
        private eventService: EventService
    ){ 
        this.getPreviousPath();
    }

    getUserPlatform(): Platform {
        return this.platform;
    }

    setPageTitle( title: string ) {
        this.titleService.setTitle( title );
    }

    windowScrollTop() {    
        if ( window.scrollY > 0 ) window.scroll(0,0);
    }

    windowScrollY( y: number) { 
        window.scroll( 0, y );
    }

    listenScrolling( target: HasEventTargetAddRemove<Event> ): Observable<responseScrolling> {
        this.scroll$ = fromEvent( target, 'scroll' );
        return this.scroll$
        .pipe(
            map<Event, responseScrolling>( event =>  {
            if ( target === document.querySelector('mat-dialog-container') ) {
                    const { clientHeight, scrollHeight, scrollTop } = event.target as any;
                    return { clientHeight, scrollHeight, scrollTop }
            } else {
                    const { clientHeight, scrollHeight, scrollTop } = event.target['documentElement'];
                    return { clientHeight, scrollHeight, scrollTop }
            }
            })
        );
    }

    // listen scrolling when it reaches the end
    checkTheEndOfScrolling( data: responseScrolling ): Promise<boolean> {
        return new Promise( resolve => {

            const scrollingResult  = ( data.scrollTop / ( data.scrollHeight - data.clientHeight ) * 100 );
            if ( scrollingResult >= 85 ) {
                    resolve( true );
            }

        });
    }

    getLocation(): Promise<ResponseIpinfo> {

        return new Promise( async resolve => {

            try {

                const request = await fetch(`https://ipinfo.io/json?token=${ environment.tokenIpinfo }`)
                const response = await request.json();
    
                resolve(response);
                
            } catch (err) {

                resolve(null);
                
            }

        });

    }

    validateImageOrVideoFileType( file: File ): Promise<string | boolean> {

        return new Promise( resolve => {

            const errorMessage = 'El archivo no es una imagen o un video permitido';

            if ( file.type.length > 0 ) {
                
                const allowedExtensions = ['png', 'jpg', 'jpeg', 'webp', 'mp3', 'mp4'];
    
                if ( !allowedExtensions.includes( file.type.split('/')[1].toLowerCase() ) ) {
                        resolve( errorMessage );
    
                } else {
                        resolve( true );
    
                }

            } else {
                resolve( errorMessage );
            }



        });

    }

    validateImageFileType( file: File ): Promise<string | boolean> {

        return new Promise( resolve => {

            const errorMessage = 'El archivo no es una imagen permitida';

            if ( file.type.length > 0 ) {

                const allowedExtensions = ['png', 'jpg', 'jpeg', 'webp'];
    
                if ( !allowedExtensions.includes( file.type.split('/')[1].toLowerCase() ) ) {
                        resolve( errorMessage );
    
                } else {
                        resolve( true );
    
                }

            } else {
                    resolve( errorMessage );
            }

        });

    }

    validateFileSize( file: File ): Promise<string | boolean> {

        return new Promise( resolve => {

            if ( file.size > 20000000 && file.type.includes('video') ) {
                    resolve('El video no debe superar los 20 MB');

            } else if ( file.size > 20000000 && file.type.includes('image') ) {
                    resolve('La imagen no debe superar los 20 MB');
            
            } else {
                resolve(true);

            }

        });

    }

    convertFileBase64( file: File | Blob ): Promise<any> {

        return new Promise( resolve => {

            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => resolve( reader.result );
            
        });

    }
    
    decryptUserTokenAndReturnTheId( token: string ): Promise<string> {

        return new Promise( resolve => {
    
            if ( token ) {

                try {
                    const { user: { _id } } = JSON.parse( atob( token.split('.')[1] ) );
                    resolve(_id);
                    
                } catch {
                    this.logout();
                    
                }
    
            } else {

                if ( window.location.href.includes('/explore/events') || 
                     window.location.href.includes('/explore/people') ||
                     window.location.href.includes('/explore/profile/events') || 
                     window.location.href.includes('/explore/profile/messages') ||
                     window.location.href.includes('/explore/profile/information') || 
                     window.location.href.includes('/explore/event') ||
                     window.location.href.includes('/explore/create/event')) 
                {       
                        const url = window.location.href.split( '/explore' )[1];
                        this.handleUserStorageService.setRedirectUrl( `/explore${ url }` );
                }
                this.logout();

            }

        });

    }

    // method of removing diacritics (accents, etc...)
    removeDiacritics = ( text: string ): string => {
        return text.normalize('NFD').replace(/[\u0300-\u036f]/g,"");
    };

    routeBack() {
        this.previousRoute ? this.location.back() : this.router.navigateByUrl('/explore/events');
    }

    private getPreviousPath() {

        this.router.events.pipe(
            filter( event => event instanceof RoutesRecognized ),
            pairwise(),
            map( (routes: any) => this.previousRoute = routes[0].url )
        )
        .subscribe();
        
    }

    matchIdMongodb( idMongoDB: string ): boolean {
        return idMongoDB.match(/^[0-9a-fA-F]{24}$/) ? true : false;
    }


    checkAccessTokenAndCheckRefreshToken(): Promise<boolean> {

        return new Promise( async resolve => {

            const idAccessToken = await this.decryptUserTokenAndReturnTheId( this.handleUserStorageService.getToken('Authorization') );
            idAccessToken ? resolve( true ) : resolve( false );
            
        });

    }

    passwordEncrypt( password: string ): string {
        return AES.encrypt( password, environment.secretCryptoJS ).toString();
    }

    async checkNetwork(): Promise<boolean> {

        try {
    
          const checkNetwork = await fetch('.');
          return ( checkNetwork.status >= 200 && checkNetwork.status < 500 ) ? true : false;
          
        } catch {
            return false;
        }
    
    }

    compressImage( image: File ): Promise<File | boolean> {

        return new Promise( resolve => {

            new Compressor( image, {
                quality: 0.6,
                success(response) {
                  resolve( new File( [response], (response as any).name, { type: 'image/jpeg' } ) )
                },
                error(err) {
                  resolve(false);
                }
            } );

        });

    }

    refreshUserToken(): Observable<boolean> {

        const token = this.handleUserStorageService.getToken('Authorization');

        if ( token ) {

            try {
                const { user: { _id: idUser } } = JSON.parse( atob( token.split('.')[1] ) );
                return this.http.put( `${ this.URLBACKEND }/users-refresh-token`, { idUser } )
                    .pipe(
                        map( ( response: ResponseLogin) => {
                            this.handleUserStorageService.setToken( response.Authorization, 'Authorization' );
                            return true;
                        })
                    )
            } catch {
                this.logout();
                
            }
        }

        return of( true );

    }

    checkIfTheUserTokenHasExpiredOrIsAbout(): Promise<boolean> {

        return new Promise( resolve => {

            const token = this.handleUserStorageService.getToken('Authorization');
            let currentTimePlus1Minute = new Date();
            currentTimePlus1Minute.setTime( currentTimePlus1Minute.getTime() + 60 * 1000 );
    
            try {
                const { exp } = JSON.parse( atob( token.split('.')[1] ) );
                if ( exp <= currentTimePlus1Minute.getTime() / 1000 ) resolve( true );
                else resolve( false );

            } catch {
                resolve( false );
            }

        });

    }

    logout() {
        
        this.dataUserService.cleanUserCache('cacheUsersSimilarCategories');
        this.dataUserService.cleanUserCache('cacheUser');
        this.eventService.cleanEventCache();
        this.handleUserStorageService.removeLocalStorage();
        this.router.navigateByUrl('/login');

    }

}