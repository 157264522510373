import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { UserModel } from '@core/interfaces/user/user';

export declare type cacheNames = 'cacheUsersSimilarCategories' | 'cacheUser';

@Injectable({
    providedIn: 'root'
})
export class UserCacheService {

    readonly CACHE_DURATION_IN_MINUTES = 5;

    private cacheUsersSimilarCategories: {
        expires: number,
        value: Observable<UserModel[]>
    } = null;

    private cacheUser: {
        [id: string]: {
            expires: number,
            value: Observable<UserModel>
        }
    } = {};

    getValueCacheUsersSimilarCategories(): Observable<UserModel[]> {

        if ( !this.cacheUsersSimilarCategories ) return null;
        if ( Date.now() > this.cacheUsersSimilarCategories.expires ) {
            this.clearCache('cacheUsersSimilarCategories');
            return null;
        }
        return this.cacheUsersSimilarCategories.value;

    }

    setValueCacheUsersSimilarCategories( value: Observable<UserModel[]> ) {

        this.cacheUsersSimilarCategories = {
            expires: Date.now() + this.CACHE_DURATION_IN_MINUTES * 60 * 1000,
            value
        };
    
    }

    getValueCacheUser( idUser: string ): Observable<UserModel> {

        const item = this.cacheUser[idUser];
        if ( !item ) return null;
        if ( Date.now() > item.expires ) {
            this.clearCache('cacheUser');
            return null;
        }
        return item.value;

    }

    setValueCacheUser( idUser: string, value: Observable<UserModel> ) {

        this.cacheUser[idUser] = { 
            expires: Date.now() + this.CACHE_DURATION_IN_MINUTES * 60 * 1000,
            value 
        };

    }

    clearCache( cacheName: cacheNames ) {
        ( cacheName === 'cacheUser' ) ? this.cacheUser = {} : this[cacheName] = null;
    }

}