import { Component, EventEmitter, Output, AfterViewInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup, AbstractControl } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';

import { ResetPassword } from '@core/services/user/methods/put/reset-password.service';
import { HandleGlobalModalsService } from '@core/services/modals/handle-global-modals.service';

@Component({
  selector: 'app-form-forgot-password',
  templateUrl: './form-forgot-password.component.html',
  styleUrls: ['./form-forgot-password.component.scss']
})
export class FormForgotPasswordComponent implements AfterViewInit {

  @Output() redirectTo: EventEmitter<string> = new EventEmitter();

  formForgotPassword: FormGroup;
  submitFormForgotPassword: boolean = false;
  loadingBtnLogin: boolean = false;
  inputEmailElement: HTMLElement;

  constructor(
    private fb: FormBuilder,
    private resetPassword: ResetPassword,
    private handleGlobalModalsService: HandleGlobalModalsService 
  ) { 

    this.formForgotPassword = this.fb.group({
      email: ['', [ Validators.required,
                    Validators.pattern(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/) ] ]
    })

  }
  
  ngAfterViewInit() {
    this.inputEmailElement = document.getElementById('input-email');
  }

  sendEmail() {
    this.submitFormForgotPassword = true;

    if ( this.formForgotPassword.invalid ) return;

    this.loadingBtnLogin = true;

    // We send the user's email to receive the password change link
    this.resetPassword.forgotPassword(this.formForgotPassword.value.email).subscribe( 

        response => {

          this.loadingBtnLogin = false;
          this.submitFormForgotPassword = false;
          this.formForgotPassword.reset();

          this.handleGlobalModalsService.modalSuccess( response.message, false );
          
        },  
        (err: HttpErrorResponse) => {

            this.loadingBtnLogin = false;
            this.submitFormForgotPassword = false;

            if ( err.status >= 400 && err.status < 500 ) this.handleGlobalModalsService.modalError(err);

        }

    );

  }

  formValidators( field: string ): AbstractControl {
    return this.formForgotPassword.get(field);
  }

  redirect( direction: string ) {
    this.redirectTo.emit(direction);
  }

  keyupEnterInputEmail() {
    this.inputEmailElement.blur();
  }


}
