import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

import { Observable } from 'rxjs';

import { ResponseUserBasic } from '@core/interfaces/user/response-user-basic';

@Injectable({
    providedIn: 'root'
})

export class ResetPassword {

    private URLBACKEND = environment.urlBackend;

    constructor(
        private http: HttpClient
    ){}

    forgotPassword( email: string ): Observable<ResponseUserBasic> {
        return this.http.put<ResponseUserBasic>(`${ this.URLBACKEND }/users-forgot-password`, { email });
    }

    changeForgotPassword( tokenForgotPassword: string, newPassword: string ): Observable<ResponseUserBasic> {
        return this.http.put<ResponseUserBasic>(`${ this.URLBACKEND }/users-change-forgot-password`, { tokenForgotPassword, newPassword });
    }

}
