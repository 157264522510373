<div id="container-principal" class="animated fadeIn fast">

    <section id="container-welcome">

        <div>
            <h1>Dayvents</h1>
            <p>¡Encuentra eventos, amigos y más!</p>
            <section>
                <img appLazyloadFile [imageSrc]="'../../../assets/img/friends.webp'" alt="Happy friends with Dayvents" />
            </section>
        </div>

    </section>

    <section id="container-app-form-login">

        <app-form-login (redirectTo)="redirect($event)"></app-form-login>

    </section>

    <section id="container-footer-one">

        <app-footer-one></app-footer-one>

    </section>

</div>