import { Directive } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Directive({
    selector: '[appTrimBlackInput]',
})
export class TrimBlackInputDirective { 

    constructor(
        private ngControl: NgControl
    ){
        this.trimValueAccessor( this.ngControl.valueAccessor );
    }

    private trimValueAccessor( valueAccessor: ControlValueAccessor ) {

        const originalValueAccessor = valueAccessor.registerOnChange;

        valueAccessor.registerOnChange = ( fn: ( _: unknown ) => void ) => {

            return originalValueAccessor.call( valueAccessor, ( value: unknown ) => {
                return fn( typeof value === 'string' ? value.trim() : value );
            });
            
        }

    } 

}