import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { EventDB } from '@core/interfaces/event/event';

@Injectable({
    providedIn: 'root'
})
export class EventCacheService {

    readonly CACHE_DURATION_IN_MINUTES = 1440; // One day

    private cacheEventsCreatedByUser: {
        expires: number,
        value: Observable<EventDB[]>
    } = null;

    getValueCacheEventsCreatedByUser(): Observable<EventDB[]> {

        if ( !this.cacheEventsCreatedByUser ) return null;
        if ( Date.now() > this.cacheEventsCreatedByUser.expires ) {
            this.clearCache();
            return null;
        }
        return this.cacheEventsCreatedByUser.value;

    }

    setValueCacheEventsCreatedByUser( value: Observable<EventDB[]> ) {

        this.cacheEventsCreatedByUser = {
            expires: Date.now() + this.CACHE_DURATION_IN_MINUTES * 60 * 1000,
            value
        };
    
    }

    clearCache() {
        this.cacheEventsCreatedByUser = null;
    }

}